import React, { useEffect, useState, Fragment, useContext } from 'react';
import axios from 'axios';
import { LoginContext } from '../../hooks/context/LoginContext';
import $ from 'jquery';
import jQuery from 'jquery';
import DataTable from 'datatables.net';
import Spinner from 'react-bootstrap/Spinner'
import ReactDOM from 'react-dom';
import Cookies from 'universal-cookie';
import MiSelect from '../../componentes/formulario/MiSelect';
import BaseSelect from "react-select";
import swal from 'sweetalert';
import InformesBtn from '../../componentes/formulario/informes/informesBtn/InformesBtn';

import { alertService } from '../../services/alertservice';



import { SRLWrapper } from 'simple-react-lightbox';

import { getApiUrl, getPantDocFromNivel } from '../../helpers/helperApp';

require('datatables.net-dt');
require('datatables.net-responsive');
require('datatables.net-buttons');
require('datatables.net-bs4');

require('datatables.net/js/jquery.dataTables');
require('datatables.net-buttons/js/dataTables.buttons');
require('datatables.net-buttons/js/buttons.colVis');
require('datatables.net-buttons/js/buttons.flash');
require('datatables.net-buttons/js/buttons.html5');
require('datatables.net-buttons/js/buttons.print');
require('datatables.net-select');
require('datatables.mark.js/build/templates/datatables.mark');
require('datatables.mark.js/dist/datatables.mark.es6');
require('datatables.net-rowreorder-bs4/js/rowReorder.bootstrap4');
require('datatables.net-rowreorder');
require('datatables.net-rowgroup')


$.DataTable = DataTable;

let date = new Date();
let mesHoy = date.getMonth() + 1;
let h = date.getDate();
let hoy = (h < 10 ? h = '0'+h : h = h )+'/'+(mesHoy < 10 ? mesHoy = '0'+mesHoy : mesHoy = mesHoy )+'/'+date.getFullYear();
let collapsedGroups = {};
let categorias = [];


var botones = [
    // {
    //     extend: 'pageLength',
    //     text: `<i style='font-size: 20px;' class='fal fa-list-ol'></i>`,
    //     className: 'btn btn-flat mr-1 btn-outline-secondary',
    //     // titleAttr: 'Ver número de registros'
    // },
    // {
    //     extend: 'copy',
    //     text: `<i style='font-size: 20px;' class='fal fa-copy'></i>`,
    //     className: 'btn btn-flat mr-1 btn-outline-secondary',
    //     // titleAttr: 'Copiar al portapapeles',
    //     exportOptions: {
    //         columns: ':visible'
    //     }
    // }, 
    // {
    //     extend: 'csv',
    //     filename: hoy,
    //     text: `<i style='font-size: 20px;' class='fal fa-file-csv'></i>`,
    //     className: 'btn btn-flat mr-1 btn-outline-secondary',
    //     title: hoy,
    //     // titleAttr: 'Exportar a csv',
    //     exportOptions: {
    //         columns: ':visible'
    //     }

    // },    
    {
        extend: 'excel',
        filename: hoy,
        text: `<i style='font-size: 20px;' class='fal fa-file-excel'></i>`,
        className: 'btn btn-flat mr-1 btn-outline-secondary',
        title: hoy,
        // titleAttr: 'Exportar a excel',
        exportOptions: {
            columns: ':visible'
        }
    }, 
    {
        extend: 'pdf',
        filename: hoy,
        text: `<i style='font-size: 20px;' class='fal fa-file-pdf'></i>`,
        className: 'btn btn-flat mr-1 btn-outline-secondary',
        orientation: 'landscape', 
        title: hoy,
        // titleAttr: 'Exportar a pdf',
        exportOptions: {
            columns: ':visible'
        }
    }, 
    // {
    //     extend: 'print',
    //     filename: hoy,
    //     text: `<i style='font-size: 20px;' class='fal fa-print'></i>`,
    //     className: 'btn btn-flat mr-1 btn-outline-secondary',
    //     title: hoy,
    //     // titleAttr: 'Imprimir',
    //     customize: function ( win ) {
    //         $(win.document.body)
    //             .css( 'font-size', '22pt' );

    //         $(win.document.body).find( 'table' )
    //             .addClass( 'compact' )
    //             .css( 'font-size', '14pt' );
    //     },
    //     exportOptions: {
    //         modifier: {
    //             selected: null
    //         }
    //     },
    //     exportOptions: {
    //         columns: ':visible'
    //     }
    // },
    // {
    //     extend: 'colvis',
    //     text: `<i style='font-size: 20px;' class='fad fa-line-columns'></i>`,
    //     className: 'btn btn-flat mr-1 btn-outline-secondary',
    // }
];


function MiDataTableCheckList(props){


    const cookies = new Cookies();

    const { apiKey, confApp, logo } = useContext(LoginContext);
    

    // ***** props *****
    const { id, data, columnas, formulario, confPantalla, propiedades } = props;
    let { buttons, ordering, order, searching, paging, select, saveLoadingTable, loadingTable, columnactions, traduction, linkColumns, functionClickTd, pagelength, grouping, columnsFunctionClickTd, rowreorder, functionAfterReorder, saveLoading, getAgentes, ejecutar, ocultar, adjuntosplegados } = props;
    
    
    
    
    useEffect(()=>{        
        
        const accionesMiDataTable = columnas.find(column => column["title"] === columnactions);

        // Si ya existe la columna de acciones la eliminamos ya que sino da error cuando seleccionas un reg, vas a otra pantalla, vuelves y seleccionas otro reg.        
        if( accionesMiDataTable !== undefined ){
            columnas.splice( (columnas.length-1), 1 );
        }

        // Añadimos los enlaces a los td
        if( linkColumns != undefined ){
            for( let i = 0; i < columnas.length; i++ ){
                columnas[i].createdCell = (td, cellData, rowData, row, col) => {
                    // Aplicamos el estilo a las celdas añadiendo las clases recibidas en el sql en el key 'style'
                    if ('style' in rowData){
                        $(td).addClass(rowData["style"]);
                    }
                    if( linkColumns[i] != undefined ){
                        //Creamos los enlaces
                        // Si el enlace es a la pantalla de documentos obtenemos la pantalla que le corresponde según su id
                        if( linkColumns[i].link == "/documentos" ){
                            linkColumns[i].link = getPantDocFromNivel(cookies.get('nivel'));
                        }
                        if( linkColumns[i].data.includes("/") && linkColumns[i].link != "" ){
                            const links = linkColumns[i].data.split("/");
                            $(td).html("<a href='"+window.location.origin+linkColumns[i].link+"/"+rowData[links[0]]+"/"+rowData[links[1]]+"' >"+cellData+"</a>");
                        }else if( linkColumns[i].link != "" ){
                            $(td).html("<a href='"+window.location.origin+linkColumns[i].link+"/"+rowData[linkColumns[i].data]+"' >"+cellData+"</a>");
                        }
                    } 
                    // Si la celda contiene la cadena '/assets/' y rowData hay una key foto asumimos que es una foto
                    if( 'foto' in rowData || 'img' in rowData ){
                        if( cellData ){
                            if( cellData.includes('/assets/')  ){
                                $(td).html("<img src='"+getApiUrl(window.location.origin)+cellData+"' style='width: 100px !important; cursor: pointer;' />");
                            }
                        }else{
                            $(td).html("<span></span>");
                        }
                    }                   
                    if( functionClickTd ){
                        if( columnsFunctionClickTd ){
                            if( columnsFunctionClickTd.includes(col) ){
                                $(td).on('click', function (e) {
                                    e.preventDefault();
                                    functionClickTd(e, rowData);
                                });
                            }
                        }else{
                            $(td).on('click', function (e) {
                                e.preventDefault();
                                functionClickTd(e, rowData);
                            });
                        }                        
                    }                    
                }
            }
        }else{
            for( let i = 0; i < columnas.length; i++ ){
  
                columnas[i].createdCell = (td, cellData, rowData, row, col) => {
                        // Aplicamos el estilo a las celdas añadiendo las clases recibidas en el sql en el key 'style'
                        if ('style' in rowData){
                            $(td).addClass(rowData["style"]);
                        }
                        // Si la celda contiene la cadena '/assets/' y rowData hay una key foto asumimos que es una foto
                        if( 'foto' in rowData || 'img' in rowData ){
                            if( cellData ){
                                if( cellData.includes('/assets/')  ){

                                    let templateFoto = `
                                    <div>                                   
                                            <div class="containerImgTablePending">
                                                <img class="imgUplmTablePending" src=${getApiUrl(window.location.origin)+cellData} />
                                            </div>                                                                                                                                        
                                    </div>
                                    `;
                                    $(td).html(templateFoto);
                                    // $(td).html("<img src='"+getApiUrl(window.location.origin)+cellData+"' style='width: 100px !important; cursor: pointer' />");
                                }
                            }else{
                                $(td).html("<span></span>");
                            }
                        }
                        if( functionClickTd ){
                            if( columnsFunctionClickTd ){
                                if( columnsFunctionClickTd.includes(col) ){
                                    $(td).on('click', function (e) {
                                        e.preventDefault();
                                        functionClickTd(e, rowData);
                                    });
                                }
                            }else{
                                $(td).on('click', function (e) {
                                    e.preventDefault();
                                    functionClickTd(e, rowData);
                                });
                            }                        
                        }                    
                }
            }
        }

        buttons === undefined || buttons === true || buttons === "true" ? buttons = 'Bfrtip' : buttons = 'frtip';
        ordering === undefined ? ordering = true : ordering = ordering;
        order === undefined ? order = [[0,"asc"]] : order = order;
        searching === undefined ? searching = true : searching = searching;
        paging === undefined ? paging = true : paging = paging;
        grouping === undefined ? grouping = false : grouping = grouping;
        pagelength === undefined ? pagelength = traduction.defaultpagelength : pagelength = pagelength;
        select === undefined ? select = false : select = select;

        columnas.map((columna, i) => {
            if( i == 1 && id == "tablaLibreria"){
                columna.createdCell = (td, cellData, rowData, row, col) => {
                    // Almacenamos los nombres de las categorías y sus ids para recuperarlas en la agrupación
                    categorias[rowData.groupby] = rowData.apartado;
                }                 
            }
            let index = 5;
            let ocultar = true;
            id == "tablaLibreria" ? index = 3: index = index;
            id == "tablaRealizados" ? index = 4: index = index;
            id == "tablaAgentes" ? index = 2: index = index;
            id == "tablaRealizados" ? ocultar = false: ocultar = ocultar;
            if( i == index){                
                // Acciones
                columna.createdCell = (td, cellData, rowData, row, col) => {                     
                    let templateNecesario;
                    if( id == "tablaPlanificados"){
                        templateNecesario =  ReactDOM.render(
                            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>                                                               
                                <div className='text-center mr-1'>
                                    <button className='btn btn-outline-dark' onClick={e => {verAgentes(e, rowData)}} style={{minWidth: "120px"}}>
                                        {rowData.numexec}
                                    </button>
                                </div>
                                <div className='text-center mr-1'>
                                    <button className='btn btn-outline-success' onClick={e => {ejecutarFnTabla(e, rowData)}}>
                                        <i className="fal fa-bolt"></i>
                                    </button>
                                </div>                                
                                <div className='text-center mr-1'>
                                    <button className='btn btn-outline-primary' onClick={e => {ocultarFnTabla(e, rowData, true)}}>                                                                                
                                        <i className="fal fa-bell-slash"></i>
                                    </button>
                                </div>
                            </div>                            
                        ,td);       
                    }else if( id == "tablaRealizados" ){                                               
                        templateNecesario =  ReactDOM.render(
                            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>                                                               
                                <div className='text-center mr-1'>
                                    <button className='btn btn-outline-dark' onClick={e => {verAgentes(e, rowData)}} style={{minWidth: "120px"}}>
                                        {rowData.numexec}
                                    </button>
                                </div>
                                <div className='text-center mr-1'>
                                    <button className='btn btn-outline-success' onClick={e => {ejecutarFnTabla(e, rowData)}}>
                                        <i className="fal fa-bolt"></i>
                                    </button>
                                </div>
                                <div className='text-center mr-1'>
                                    <a href={"/documentospr/"+rowData.id_roe}>
                                        <span className='btn btn-outline-primary'><i className='fal fa-eye'></i></span>
                                    </a>
                                </div>
                                <InformesBtn
                                    select =     { confPantalla.checklist.controles.realizados.informes.select }
                                    formulario = { {id: rowData.id_roe} }
                                    getRegistro = { confPantalla.checklist.controles.realizados.informes.getregistro }
                                    loadReg =    { false }
                                    estadoForm=  { "" } 
                                    traduction=  { confPantalla.config.trad.components }
                                    btnGenerar= { confPantalla.checklist.controles.realizados.informes.btngenerar.title }
                                    logoContext={logo}
                                    apiKeyContext={apiKey}
                                    confAppContext={confApp}
                                /> 
                                {
                                    rowData.mostrarbtn == 'SI'
                                    ?
                                        <div className='text-center mr-1'>
                                            <button className='btn btn-outline-primary' onClick={e => {ocultarFnTabla(e, rowData, false)}}>
                                                <i className="fal fa-bell"></i>                                                                                
                                            </button>
                                        </div> 
                                    :
                                        <div className='text-center' style={{width: "44px", height: "38px"}}>
                                        </div>
                                }                                
                            </div>                            
                        ,td); 
                    }else if( id == "tablaLibreria" ){
                        templateNecesario =  ReactDOM.render(
                            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>                                                               

                                <div className='text-center mr-1'>
                                    <button className='btn btn-outline-success' onClick={e => {ejecutarFnTabla(e, rowData)}}>
                                        <i className="fal fa-bolt"></i>
                                    </button>
                                </div>
                            </div>
                        ,td); 
                    }
                    else if( id == "tablaAgentes" ){
                        templateNecesario =  ReactDOM.render(
                            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                <div className='text-center mr-1'>
                                    <a href={"/browserpr/"+rowData.id+"/ag/"+rowData.fecini+"/"+rowData.fecfin} >
                                        <span className='btn btn-outline-dark'>{rowData.acciones}</span>
                                    </a>
                                </div>
                            </div>
                        ,td); 
                    }              
                                                    
                    $(td).addClass(rowData.style);
                    $(td).html(templateNecesario)                                        
                }
                    
            }
        })


        if( data ){
            if( data.length > 0 ){
                if ( $.fn.DataTable.isDataTable("."+id) ) {
                    let oTable = $("."+id).DataTable();
                    oTable.clear( );
                    for (let i = 0; i < data.length; i++){
                        oTable.row.add(data[i]);      
                    }
                    oTable.draw();               
                }else{
                    $("."+id).DataTable({
                        mark: true,
                        rowId: function(a) {
                            return '#row-' + a.id_linea;},
                        dom: buttons,
                        data: data,
                        columns: columnas,
                        ordering: ordering,
                        order: order,
                        searching: searching,
                        paging: paging,
                        select: select,
                        buttons: [botones],
                        // fixedHeader: true,
                        // scrollX: true,
                        responsive: false, 
                        autoWidth: false,
                        retrieve: true,
                        pageLength: parseInt(pagelength),
                        lengthMenu: [
                            [ 10, 25, 50, 100 ],
                            [ '10', '25', '50', '100' ]
                        ],
                        rowReorder: rowreorder ? true : false,
                        deferRender: true,
                        language: {
                            "sProcessing":    traduction.sprocessing.title,
                            "sLengthMenu":    traduction.slengthmenu.title,
                            "sZeroRecords":   traduction.szerorecords.title,
                            "sEmptyTable":    traduction.semptytable.title,
                            "sInfo":          traduction.sinfo.title,
                            "sInfoEmpty":     traduction.sinfoempty.title,
                            "sInfoFiltered":  traduction.sinfofiltered.title,
                            "sInfoPostFix":   traduction.sinfopostfix.title,
                            "sSearch":        traduction.ssearch.title,
                            "sUrl":           traduction.surl.title,
                            "sInfoThousands":  traduction.sinfothousands.title,
                            "sLoadingRecords": traduction.sloadingrecords.title,
                            "oPaginate": {
                                "sFirst":    traduction.sfirst.title,
                                "sLast":    traduction.slast.title,
                                "sNext":    traduction.snext.title,
                                "sPrevious": traduction.sprevious.title
                            },
                            "oAria": {
                                "sSortAscending":  traduction.ssortascending.title,
                                "sSortDescending": traduction.ssortdescending.title
                            },
                            select: {
                                rows: traduction.rows.title
                            },
                            buttons: {
                                copyTitle: traduction.copytitle.title,
                                copyKeys: traduction.copykeys.title,
                                copySuccess: {
                                    _: traduction.copysuccess.many.title,
                                    1: traduction.copysuccess.one.title
                                },
                            }        
                        },
                        rowGroup: {
                            // Uses the 'row group' plugin
                            dataSrc: "groupby",
                            startRender: function (rows, group) {
                                if( id == "tablaLibreria" ){
                                    let collapsed = !!collapsedGroups[group];                                 
                                    const idGroup = group;
    
                                    if(adjuntosplegados){
                                        Object.keys(collapsedGroups).length == 0 && idGroup != "0" ? collapsed = true: collapsed = !collapsedGroups[group];
                                    }
                        
                                    rows.nodes().each(function (r) {
                                        r.style.display = collapsed ? 'none' : '';
                                    });    
    
                                    let nombreCategoria = "";
                                    if(group == 0 || group == "No group"){ 
                                        nombreCategoria = traduction.sintipo.title;                                                                      
                                    }else{                                    
                                        categorias.map((cat,index) =>{
                                            if(index == group){
                                                nombreCategoria = cat;
                                            }                                         
                                        })          
                                    }
                                    // Add category name to the <tr>. NOTE: Hardcoded colspan
                                    return $('<tr/>')
                                        .append('<td colspan="5" style="background-Color: rgba(0,0,0,.05); cursor: pointer"><h6>' + nombreCategoria + ' (' + rows.count() + ')</h6></td>')
                                        .attr('data-name', group)
                                        .toggleClass('collapsed', collapsed)
                                        .bind('click', (e, group) => expandCollapse(e, idGroup));
                                } 
                            }
                        }, 
                        rowCallback : function (row, data, index){ 
                            // Si td campo incluye un # asumimos que es un color con lo que se lo ponemos de fondo
                            for (let item of row.children) {
                                if( item.outerText.includes("#") ){
                                    item.bgColor = item.outerText;
                                    item.innerText = "";
                                }
                            }
                        }
                    })
                }  
                // saveLoadingTable(false);       
            }else{
                if ( $.fn.DataTable.isDataTable("."+id) ) {
                    let oTable = $("."+id).DataTable();
                    oTable.clear();
                    oTable.draw();               
                }{               
                    $("."+id).DataTable({
                        mark: true,
                        rowId: function(a) {
                            return '#row-' + a.id_linea;},
                        dom: buttons,
                        data: null,
                        columns: columnas,
                        ordering: ordering,
                        order: order,
                        searching: searching,
                        paging: paging,
                        select: select,
                        buttons: [botones],
                        // fixedHeader: true,
                        // scrollX: true,
                        responsive: false, 
                        autoWidth: false,
                        retrieve: true,
                        pageLength: parseInt(pagelength),
                        lengthMenu: [
                            [ 10, 25, 50, 100 ],
                            [ '10', '25', '50', '100' ]
                        ],
                        rowReorder: rowreorder ? true : false,
                        deferRender: true,
                        language: {
                            "sProcessing":    traduction.sprocessing.title,
                            "sLengthMenu":    traduction.slengthmenu.title,
                            "sZeroRecords":   traduction.szerorecords.title,
                            "sEmptyTable":    traduction.semptytable.title,
                            "sInfo":          traduction.sinfo.title,
                            "sInfoEmpty":     traduction.sinfoempty.title,
                            "sInfoFiltered":  traduction.sinfofiltered.title,
                            "sInfoPostFix":   traduction.sinfopostfix.title,
                            "sSearch":        traduction.ssearch.title,
                            "sUrl":           traduction.surl.title,
                            "sInfoThousands":  traduction.sinfothousands.title,
                            "sLoadingRecords": traduction.sloadingrecords.title,
                            "oPaginate": {
                                "sFirst":    traduction.sfirst.title,
                                "sLast":    traduction.slast.title,
                                "sNext":    traduction.snext.title,
                                "sPrevious": traduction.sprevious.title
                            },
                            "oAria": {
                                "sSortAscending":  traduction.ssortascending.title,
                                "sSortDescending": traduction.ssortdescending.title
                            },
                            select: {
                                rows: traduction.rows.title
                            },
                            buttons: {
                                copyTitle: traduction.copytitle.title,
                                copyKeys: traduction.copykeys.title,
                                copySuccess: {
                                    _: traduction.copysuccess.many.title,
                                    1: traduction.copysuccess.one.title
                                },
                            }         
                        },
                        rowGroup: {
                            // Uses the 'row group' plugin
                            dataSrc: "groupby",
                            startRender: function (rows, group) {
                                if( id == "tablaLibreria" ){
                                    let collapsed = !!collapsedGroups[group];                                 
                                    const idGroup = group;
    
                                    if(adjuntosplegados){
                                        Object.keys(collapsedGroups).length == 0 && idGroup != "0" ? collapsed = true: collapsed = !collapsedGroups[group];
                                    }
                        
                                    rows.nodes().each(function (r) {
                                        r.style.display = collapsed ? 'none' : '';
                                    });    
    
                                    let nombreCategoria = "";
                                    if(group == 0 || group == "No group"){ 
                                        nombreCategoria = traduction.sintipo.title;                                                                      
                                    }else{                                    
                                        categorias.map((cat,index) =>{
                                            if(index == group){
                                                nombreCategoria = cat;
                                            }                                         
                                        })          
                                    }
                                    // Add category name to the <tr>. NOTE: Hardcoded colspan
                                    return $('<tr/>')
                                        .append('<td colspan="5" style="background-Color: rgba(0,0,0,.05); cursor: pointer"><h6>' + nombreCategoria + ' (' + rows.count() + ')</h6></td>')
                                        .attr('data-name', group)
                                        .toggleClass('collapsed', collapsed)
                                        .bind('click', (e, group) => expandCollapse(e, idGroup));
                                } 
                            }
                        }, 
                        rowCallback : function (row, data, index){
                            // Si td campo incluye un # asumimos que es un color con lo que se lo ponemos de fondo
                            for (let item of row.children) {
                                if( item.outerText.includes("#") ){
                                    item.bgColor = item.outerText;
                                    item.innerText = "";
                                }
                            }


                        }
                    })
                }
                // saveLoadingTable(false);
            }
        }else{
            if ( $.fn.DataTable.isDataTable("."+id) ) {
                let oTable = $("."+id).DataTable();
                oTable.clear();
                oTable.draw();               
            }{               
                $("."+id).DataTable({
                    mark: true,
                    rowId: function(a) {
                        return '#row-' + a.id_linea;},
                    dom: buttons,
                    data: null,
                    columns: columnas,
                    ordering: ordering,
                    order: order,
                    searching: searching,
                    paging: paging,
                    select: select,
                    buttons: [botones],
                    // fixedHeader: true,
                    // scrollX: true,
                    responsive: false, 
                    autoWidth: false,
                    retrieve: true,
                    pageLength: parseInt(pagelength),
                    lengthMenu: [
                        [ 10, 25, 50, 100 ],
                        [ '10', '25', '50', '100' ]
                    ],
                    rowReorder: rowreorder ? true : false,
                    deferRender: true,
                    language: {
                        "sProcessing":    traduction.sprocessing.title,
                        "sLengthMenu":    traduction.slengthmenu.title,
                        "sZeroRecords":   traduction.szerorecords.title,
                        "sEmptyTable":    traduction.semptytable.title,
                        "sInfo":          traduction.sinfo.title,
                        "sInfoEmpty":     traduction.sinfoempty.title,
                        "sInfoFiltered":  traduction.sinfofiltered.title,
                        "sInfoPostFix":   traduction.sinfopostfix.title,
                        "sSearch":        traduction.ssearch.title,
                        "sUrl":           traduction.surl.title,
                        "sInfoThousands":  traduction.sinfothousands.title,
                        "sLoadingRecords": traduction.sloadingrecords.title,
                        "oPaginate": {
                            "sFirst":    traduction.sfirst.title,
                            "sLast":    traduction.slast.title,
                            "sNext":    traduction.snext.title,
                            "sPrevious": traduction.sprevious.title
                        },
                        "oAria": {
                            "sSortAscending":  traduction.ssortascending.title,
                            "sSortDescending": traduction.ssortdescending.title
                        },
                        select: {
                            rows: traduction.rows.title
                        },
                        buttons: {
                            copyTitle: traduction.copytitle.title,
                            copyKeys: traduction.copykeys.title,
                            copySuccess: {
                                _: traduction.copysuccess.many.title,
                                1: traduction.copysuccess.one.title
                            },
                        }       
                    },
                    rowGroup: {
                        // Uses the 'row group' plugin
                        dataSrc: "groupby",
                        startRender: function (rows, group) {
                            if( id == "tablaLibreria" ){
                                let collapsed = !!collapsedGroups[group];                                 
                                const idGroup = group;

                                if(adjuntosplegados){
                                    Object.keys(collapsedGroups).length == 0 && idGroup != "0" ? collapsed = true: collapsed = !collapsedGroups[group];
                                }
                    
                                rows.nodes().each(function (r) {
                                    r.style.display = collapsed ? 'none' : '';
                                });    

                                let nombreCategoria = "";
                                if(group == 0 || group == "No group"){ 
                                    nombreCategoria = traduction.sintipo.title;                                                                      
                                }else{                                    
                                    categorias.map((cat,index) =>{
                                        if(index == group){
                                            nombreCategoria = cat;
                                        }                                         
                                    })          
                                }
                                // Add category name to the <tr>. NOTE: Hardcoded colspan
                                return $('<tr/>')
                                    .append('<td colspan="5" style="background-Color: rgba(0,0,0,.05); cursor: pointer"><h6>' + nombreCategoria + ' (' + rows.count() + ')</h6></td>')
                                    .attr('data-name', group)
                                    .toggleClass('collapsed', collapsed)
                                    .bind('click', (e, group) => expandCollapse(e, idGroup));
                            } 
                        }
                    }, 
                    rowCallback : function (row, data, index){
                        // Si algún td incluye un # asumimos que es un color con lo que se lo ponemos de fondo
                        for (let item of row.children) {
                            if( item.outerText.includes("#") ){
                                item.bgColor = item.outerText;
                                item.innerText = "";
                            }
                        }                        
                    }
                })
            }
            // saveLoadingTable(false);
            
        }
        
        if( saveLoadingTable ){
            saveLoadingTable(false); 
        }

        
        // Código para llamar a una función tras reordenar las filas
        if( functionAfterReorder != null  ){
            let tabla = $('#'+id).DataTable();
            let rows = tabla.rows().data();
            tabla.on( 'row-reorder', function ( e, diff, edit ) {
                    functionAfterReorder( e, diff, edit);         
            } );
        }

    }, [data, formulario.id])

    const expandCollapse = (e, group) => {
        collapsedGroups[group] = !collapsedGroups[group];
        let table = $("."+id).DataTable();
        table.draw(false);
    }
        
    const ocultarFnTabla = async (e, rowData, ocultarParam) => {        
        e.stopPropagation();
        ocultar(rowData, ocultarParam);
    }

    const verAgentes = async (e, rowData) => {
        e.stopPropagation();
        getAgentes(rowData);
    }
    
    const ejecutarFnTabla = async (e, rowData) => {
        e.stopPropagation();
        ejecutar(rowData, id);        
    }    

    // Código para evitar los acentos en las búsquedas
    function removeAccents ( data ) {
        if ( data.normalize ) {
            // Use I18n API if avaiable to split characters and accents, then remove
            // the accents wholesale. Note that we use the original data as well as
            // the new to allow for searching of either form.
            return data +' '+ data
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '');
        }
     
        return data;
    }
    var searchType = jQuery.fn.DataTable.ext.type.search;
 
    searchType.string = function ( data ) {
        return ! data ?
            '' :
            typeof data === 'string' ?
                removeAccents( data ) :
                data;
    };
    
    searchType.html = function ( data ) {
        return ! data ?
            '' :
            typeof data === 'string' ?
                removeAccents( data.replace( /<.*?>/g, '' ) ) :
                data;
    };
    // FIN: Código para evitar los acentos en las búsquedas




    return(
        <Fragment>
            {   loadingTable ? 
                    <div style={{height:50, marginTop:5, marginBottom:10, textAlign: "center"}} >
                        <Spinner animation="border" />
                    </div>
                :
                null
            }
            {
                data == null
                ?
                    null
                :
                    <div style={{overflowX: "auto"}}>
                        <table 
                            id={id} 
                            className={
                                id == "tablaPlanificados" || id == "tablaLibreria" 
                                ? id+" table table-sm table-bordered table-hover dataTable"
                                : id+" table table-sm table-bordered table-striped table-hover dataTable"                                
                            } 
                            style={{width:"99%"}} >                            
                        </table>
                    </div>  
            }                        
        </Fragment>
    )
}

export default MiDataTableCheckList;