import React, { useState, useContext, useEffect } from 'react';
import makeAnimated from 'react-select/animated';
import BaseSelect from "react-select";
import FixRequiredSelect from "../../../helpers/FixRequiredSelect";
import axios from 'axios';
import Cookies from 'universal-cookie';


import { LoginContext } from '../../../hooks/context/LoginContext';

import { getApiUrl, axiosPost, MiReplaceAll } from '../../../helpers/helperApp';
import { Fragment } from 'react';

import MiDataTableListaRel from './MiDataTableListaRel';
import MiSelectListaRel from './MiSelectListaRel';


function ListaRel(props){

    const cookies = new Cookies();
    // const CancelToken = axios.CancelToken;
    // const source = CancelToken.source();
    // const source2 = CancelToken.source();

    const { apiKey } = useContext(LoginContext);

    const { estadoForm, val, loading, id, name, label, placeholder, col, isClearable, isSearchable, dataType, data, required, disabled, readOnly, disablethisid, formulario, saveFormulario, table, traduction, selectSonUsuarios } = props;

    const [loadingSelect, saveLoadingSelect] = useState(loading);

    const [options, saveOptions] = useState([]);
    const [datosTable, saveDatosTable] = useState(null);

    const animatedComponents = makeAnimated();   

    const customStyles = {
        control: (base, state) => ({
            ...base,
            borderColor:'#ced4da',
            borderColor: state.isFocused ? '#80bdff': '#ced4da',
            outline: 0,
            boxShadow: state.isFocused ? '0 0 0 0.2rem rgb(0 123 255 / 25%)' : 'inherit',
          })
    }
    const nodisplay = {
        control: (base, state) => ({
            ...base,
            opacity: 0
          })
    }

    const Select = props => (
        <FixRequiredSelect
          {...props}
          SelectComponent={BaseSelect}
          options={props.options || options}
        />
    );
    
    useEffect( () => { 

        // enviamos la petición                             
        getDataCbo(dataType, data)
        .then((res)=>{
           disableSelectedOptions(res);
           return res
        })
        .then((res)=>{
           saveOptions(res);
        });

        // Obtenemos todos los option seleccionados y guardamos sus ids en un array para rellenar el datatable con los ids de los options seleccionados
        const selectedOptions = document.querySelectorAll("#auxSelect-"+name+" option:checked");
        let aux = [];
        for(let i = 0; i < selectedOptions.length; i++){            
            aux.push(selectedOptions[i].value);
        }       

        getDataDataTable(aux)
        .then((res) => {
            saveDatosTable(res);
        });

        return () =>{    
            // Token cancelación axios al desmontar componentes
            // source.cancel();
            // source2.cancel();
        }

    },[loadingSelect, loading, formulario.id, val])

    const getDataDataTable = async (opciones) =>{

        const data = new FormData();
        data.append("ids", opciones);
        data.append("idreg", formulario.id);
        data.append("validacion", apiKey());
        const res = await axios.post(getApiUrl(window.location.origin)+table.data.url, data);
        // const res = await axiosPost(getApiUrl(window.location.origin)+table.data.url, data, source.token);        

        return res.data;
    }

    const disableSelectedOptions = (opt) => {

        if( typeof opt != "string" ){
            // Deshabilitamos las opciones que ya están guardadas
            opt.map((option) => {                              
                if( formulario[id].includes(option.value) || (selectSonUsuarios && option.orden < cookies.get('nivel') ) ){
                    option["isDisabled"] = true;
                }else{
                    option["isDisabled"] = false;
                }
            }) 
        }
        
        return opt;

    }

    const getDataCbo = async (fuente, opcion) => {
        if( fuente != "codigo" ){
            const data = new FormData();
            const validacion = apiKey();
            if( opcion.includes("usu_actual") ){
                opcion = MiReplaceAll(opcion, "usu_actual", cookies.get('id'));
            }
            data.append("validacion", validacion);
            data.append(fuente, opcion);
            const respuesta = await axios.post(getApiUrl(window.location.origin)+'/app/getDataCbo',data);
            // const respuesta = await axiosPost(getApiUrl(window.location.origin)+'/app/getDataCbo', data, source2.token);
    
            // Si está activada la opción para que desactive el option que tenga el mismo id que el del registro del formulario lo recorremos y le añadimos la opción de deshabilitarlo
            if( disablethisid ){
                respuesta.data.map((option) => {               
                    if(option.value == formulario.id){
                        option["isDisabled"] = true;
                    }
                })
            }
            
            return respuesta.data;

        }else{

            return opcion;
        }

    }  



    const handleSelect = (selectedItem, nameOfComponent) => {
        const selectAuxOptions = document.querySelector("#auxSelect-"+name).options;
   
        if( selectedItem ){
            // 1 - Ponemos como seleccionado el option que acabamos de añadir
            const option = document.querySelector("#auxSelect-"+name+" option[value='"+selectedItem.value+"']");
            if( option ){
                option.selected = true;
        
                // 2 - Obtenemos todos los option seleccionados y guardamos 
                const selectedOptions = document.querySelectorAll("#auxSelect-"+name+" option:checked");
                let aux = [];
                for(let i = 0; i < selectedOptions.length; i++){
                    aux.push(selectedOptions[i].value);
                }        
                saveFormulario({ ...formulario, [name]:aux })        
            }
        }
    }

    const handleDeleteRel = (e) => {  
        if( e ){
            // 1 - Ponemos como NO seleccionado el option que acabamos de borrar
            const option = document.querySelector("#auxSelect-"+name+" option[value='"+e.target.id+"']");
            if( option ){
                option.selected = false;
        
                // 2 - Obtenemos todos los option seleccionados y guardamos sus ids en un array
                const selectedOptions = document.querySelectorAll("#auxSelect-"+name+" option:checked");
                let aux = [];
                for(let i = 0; i < selectedOptions.length; i++){
                    aux.push(selectedOptions[i].value);
                }        
                // saveFormulario({ ...formulario, [name]:aux }) 
                getDataDataTable(aux)
                .then((res) => {                    
                    saveDatosTable(res);
                }); 
                activeOptions(aux);
            }
        }
    }

    const activeOptions = (ids) => {
        options.map((option) => { 
            if( ids.includes(option.value) ){
                option["isDisabled"] = true;
            }else{
                option["isDisabled"] = false;
            }
        }) 
        saveOptions(options);
    }


    return(
        <Fragment>

                    <MiSelectListaRel
                            options = { options }
                            estadoForm = { estadoForm } 
                            loading = { loading } 
                            id = { id }
                            label = { label } 
                            placeholder = { placeholder } 
                            col = { col }
                            isClearable = { isClearable }
                            isSearchable = { isSearchable } 
                            required = { required } 
                            disabled = { disabled } 
                            readOnly = { readOnly }
                            handleSelect = { handleSelect }
                            loadingSelect = { loadingSelect }
                            formulario = { formulario }
                    />
                    {
                        options.length > 0
                        ?
                        <MiDataTableListaRel 
                           estadoForm= { estadoForm }
                           id={ "table-"+id }
                           columnas={ table.columns }
                           data={ datosTable }
                           buttons={ table.buttons }
                           ordering={ table.ordering }
                           order={ table.order }
                           searching={ table.searching }
                           paging={ table.paging }
                           select={ table.select }
                           pagelength={table.pagelength}
                           handleDeleteItem={ handleDeleteRel }
                           columnactions={ table.columnactions.title }
                           traduction={ traduction }
                           optionsSelect = { options }
                           disabledSelect = { disabled }
                           readOnlySelect = { readOnly }
                           linkColumns = {table.linkColumns }
                           selectSonUsuarios={selectSonUsuarios}
                       />    
                        : 
                        null
                    }
                    {

                        <select id={"auxSelect-"+name} name={name+"[]"} multiple="multiple" className="noDisplay"> 
                        {/* <select id={"auxSelect-"+name} name={name+"[]"} multiple="multiple">                                                */}
                            {
                                options.map((option) => {
                                    if( val.includes(option.value) ){
                                        return <option key={option.value} value={option.value} selected>{option.label}</option>
                                    }else{
                                        return <option key={option.value} value={option.value}>{option.label}</option>
                                    }
                                })
                            }
                        </select>

                    }
        </Fragment>
        
       
    )
}

export default ListaRel;