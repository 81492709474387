import React, { useEffect, useContext, useState } from 'react';
import $ from 'jquery';
import DataTable from 'datatables.net';
import { LoginContext } from '../../hooks/context/LoginContext';
import axios from 'axios';

import Menusup from '../../componentes/menus/menusup/Menusup';
import Sidebar from '../../componentes/menus/sidebar/Sidebar';
import Footer from '../../componentes/footer/Footer';
import Section from '../../componentes/ui/Section';
import MiDataTable from '../../componentes/ui/MiDataTable';
import PermissionRefused from '../../componentes/ui/PermissionRefused';
import LoadingPage from '../../componentes/ui/LoadingPage';
import Kpi from '../../componentes/ui/Kpi';
import Button from '../../componentes/ui/Button';
import { Spinner } from 'react-bootstrap';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Modal from 'react-bootstrap/Modal';
import MiSelect from '../../componentes/formulario/MiSelect';
import MiSelectMultiple from '../../componentes/formulario/MiSelectMultiple';
import FormInput from '../../componentes/formulario/FormInput';
import FormRow from '../../componentes/formulario/FormRow';
import MiDataTablePlanificadorPr from './MiDataTablePlanificadorPr';
import MiDataTablePlantillasPlanificador from './MiDataTablePlantillasPlanificador';
import MiDataTableEdicion from './MiDataTableEdicion';
import MiDataTableRepeticiones from './MiDataTableRepeticiones';
import Gant from '../../componentes/ui/Gant';
import { Accordion, Card } from "react-bootstrap";


import { alertService } from '../../services/alertservice';
import { Alert } from '../../componentes/ui/Alert';

import useLogicForm from '../../hooks/useLogicForm';

import Cookies from 'universal-cookie';

import { getApiUrl, axiosPost, getPantDocFromNivel, getAhorayyyymmdd, MiReplaceAll, getTitle, stringToHtml } from '../../helpers/helperApp';

import { Fragment } from 'react';
import { NavLink } from 'react-router-dom';


$.DataTable = DataTable;

let reordenandoLineas = false;

function PlanificadorPr(props){

    const cookies = new Cookies();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const source2 = CancelToken.source();
    const source3 = CancelToken.source();
    const source4 = CancelToken.source();

    // ***** Context *****
    const { validateSesion, socket, confApp, saveConfApp, getConfApp, apiKey, centroActivo } = useContext(LoginContext);   
    
    // ***** State para la configuración de la pantalla *****
    const [ confPantalla, saveConfPantalla ] = useState(confApp)
    const [ loadedConf, saveLoadedConf ] = useState(false);
    const [ tieneNivel, saveTieneNivel ] = useState(true);    
    const [ acordeonPlantillas, saveAcordeonPlantillas ] = useState(null);
    // Plantillas
    const [ datosTablaPlantillas, saveDatosTablaPlantillas ] = useState([]);
    const [ infoPlantSeleccionada, saveInfoPlantSeleccionada ] = useState(null)
    const [ loadingTablaPlantillas, saveLoadingTablaPlantillas ] = useState(false);
    // Plantillas Planificador
    const [ datosTablaPlantillasPlanificador, saveDatosTablaPlantillasPlanificador ] = useState([]);
    const [ idPlantillaPlanificador, saveIdPlantillaPlanificador ] = useState(null);
    const [ loadingTablaPlantillasPlanificador, saveLoadingTablaPlantillasPlanificador ] = useState(false);
    const [ titleEditar, saveTitleEditar ] = useState("");
    // Tabla edición
    const [ datosTablaEdicion, saveDatosTablaEdicion ] = useState([]);    
    const [ loadingTablaEdicion, saveLoadingTablaEdicion ] = useState(false);
    // Tabla repeticones
    const [ datosTablaRepeticiones, saveDatosTablaRepeticiones ] = useState([]);
    const [ loadingRepeticiones, saveLoadingRepeticiones ] = useState(false);
    // Varios
    const [ optionsEst, saveOptionsEst ] = useState([]);
    const [ showModal, saveShowModal ] = useState(false);
    const [ centro, saveCentro ] = useState(centroActivo);
    const [ fecIni, saveFecIni ] = useState(getAhorayyyymmdd());
    const [ fecFin, saveFecFin ] = useState("");
    const [ descripcion, saveDescripcion ] = useState("");
    const [ descripcionEN, saveDescripcionEN ] = useState("");
    const [ enviandoDocumento, saveEnviandoDocumento ] = useState(false);
    const [ accion, saveAccion ] = useState("");
    const [ idClonar, saveIdClonar ] = useState("");    



    // ***** Custom Hook - Lógica de los formularios *****
    const { registrosPrin, saveRegistrosPrin, formularioPrin, saveFormularioPrin, adjuntosPrin, saveAdjuntosPrin, adjuntosExistentesPrin, saveAdjuntosExistentesPrin, registrosHijo,  formularioHijo, saveFormularioHijo, adjuntosHijo, saveAdjuntosHijo, adjuntosExistentesHijo, saveAdjuntosExistentesHijo, handleInputForm, handleLastReg, handleEditRegPrin, loadingTable, saveLoadingTable, loadReg, regIniciales, handleEditRegHijo, handleDeleteItemPrin, handleDeleteItemHijo, handleSubmitPrin, handleSubmitHijo, resetFormPrin, resetFormHijo, handleSelectFormPrin, handleSelectFormHijo, handleSelectFormPrinAndSetValue, handleSelectFormHijoAndSetValue, handleClonePrin, handleCloneHijo } = useLogicForm();

    useEffect( () => {        
        
        // ***** Validamos que el usuario esté logueado sino lo enviamos al login*****
        validateSesion(props)
            .then( (respuesta) => {
                if( confPantalla === undefined ){
                    return getConfApp();
                }else{ 
                    document.title = getTitle(confPantalla.config.trad, confPantalla.planificador.title);                    
                    if( respuesta == 1 ){
                        saveTieneNivel(true); 
                        init();                                                                                                       
                    }
                    else if( respuesta == 0 ){
                        saveTieneNivel(false);
                    }                   
                    saveLoadedConf(true);        
                }
            })
            .then( (respuesta) => {
                if( respuesta !== undefined  ){
                    saveConfPantalla(respuesta.msg);
                    saveConfApp(respuesta.msg);             
                }
            })          

        // Liberamos todos los registros al salir de la pantalla
        return () =>{
            if( socket != null ){
                socket.emit("salirPantalla", { "idusu": cookies.get('id'), "tabla": "undefined" })
            }
                document.querySelector("body").classList.remove("sidebar-open");

                // Token cancelación axios al desmontar componentes
                source.cancel();
                source2.cancel();
                source3.cancel();
                source4.cancel();
        } 

    },[confPantalla, formularioHijo.id, centroActivo])

    const toogleAcordeon = (id, acordeon) => {
        switch(acordeon){        
            case "acordeonPlantillas":
                if( acordeonPlantillas == id ){
                    saveAcordeonPlantillas(null);
                    cookies.set('defaultActiveKey', null, {path: '/'});
                }else{
                    saveAcordeonPlantillas(id);
                    cookies.set('defaultActiveKey', id, {path: '/'});
                }                
            break;           
        }
    }

    const loadOptionsCbo = async (fuente, opcion, options, saveOptions) =>{        
        const data = new FormData();
        data.append("validacion", apiKey());
        if( opcion.includes("usu_actual") ){
            opcion = MiReplaceAll(opcion, "usu_actual", cookies.get('id'));
        }
        data.append(fuente, opcion);                    

        if( options.length == 0 ){
            let url = null;
            if( fuente == "filter" ){
                url = getApiUrl(window.location.origin)+'/filters/getFilter';
            }else{
                url = getApiUrl(window.location.origin)+'/app/getDataCbo';
            }
            const respuesta = await axios.post(url, data);
            saveOptions(respuesta.data)            
        }
    }

    const init = () => {
        getPlantillas();
        getPlantillasPlanificador();
        const est = confPantalla.planificadorpr.controles.tablaedi.controles.idest;
        loadOptionsCbo(est.datatype,est.data, optionsEst, saveOptionsEst);     
    }

    const getPlantillas = async() => {
        saveLoadingTablaPlantillas(true);

        const data = new FormData();
        data.append("validacion", apiKey());

        const respuesta = await axiosPost(getApiUrl(window.location.origin)+ confPantalla.planificadorpr.controles.plantillas.getplantillas, data, source.token);
        
        if( typeof respuesta.data == "string" ){
            alertService.error(`<i class="fal fa-check-circle mr-1"></i>${respuesta.data}`, {autoClose: false});
        }else if( typeof respuesta.data == "object" ){
            saveDatosTablaPlantillas(respuesta.data);   
        }

        saveLoadingTablaPlantillas(false);
    }

    const getPlantillasPlanificador = async() => {
        saveLoadingTablaPlantillasPlanificador(true);

        const data = new FormData();
        data.append("validacion", apiKey());

        const respuesta = await axiosPost(getApiUrl(window.location.origin)+ confPantalla.planificadorpr.controles.plantillasplan.getplantillas, data, source.token);
        
        if( typeof respuesta.data == "string" ){
            alertService.error(`<i class="fal fa-check-circle mr-1"></i>${respuesta.data}`, {autoClose: false});
        }else if( typeof respuesta.data == "object" ){
            saveDatosTablaPlantillasPlanificador(respuesta.data);   
        }

        saveLoadingTablaPlantillasPlanificador(false);
    }
    
    const selectPlantillaPlanificador = async (idRow, id, datos, selectedRows) => {
                
        let trs = document.querySelectorAll("table#tableplantillasplanificadorpr tr.selected");
        for( let i = 0; i < trs.length; i++ ){
            if(trs[i].id != idRow){
                trs[i].classList.remove("selectedRow");
                trs[i].classList.remove("selected");
            }
        }

        if(selectedRows.length == 0){
            // Quitamos la selección
            await saveIdPlantillaPlanificador(null);
            await saveDatosTablaEdicion([]);
        }else{
            // Seleccionamos una plantilla           
            await saveIdPlantillaPlanificador(idRow.split('-')[1]);            
            await getDatosTablaEdicion(idRow.split('-')[1]);
        }
    }

    const getDatosTablaEdicion = async(idDoc=null) => {        
        
        saveLoadingTablaEdicion(true);        

        const data = new FormData();
        data.append("validacion", apiKey());
        if(idDoc){
            data.append("iddoc", idDoc)
        }

        const respuesta = await axiosPost(getApiUrl(window.location.origin)+ confPantalla.planificadorpr.controles.tablaedi.getDatosTablaEdicion, data, source.token);
        
        if( typeof respuesta.data == "string" ){
            alertService.error(`<i class="fal fa-check-circle mr-1"></i>${respuesta.data}`, {autoClose: false});
        }else if( typeof respuesta.data == "object" ){
            saveDatosTablaEdicion(respuesta.data);   
        }

        saveLoadingTablaEdicion(false);
    }


    const planificar = async(registroClonar=null) => {

        let table = $("#tableplantillaspr").DataTable();
        const rows = table.rows( { selected: true } ).data();
        
        // Datos para la tabla repeticiones
        rows.map(row => {            
            row.repeticiones = 1;        
        })
        saveDatosTablaRepeticiones(rows);

        if(registroClonar == null){
            if(rows.length > 0){
                if(!idPlantillaPlanificador){          
                    // Crear. No existe documento de planificación
                    saveAccion("crear");
                    saveCentro(centroActivo == "0" ? "" : centroActivo);
                    saveFecIni(getAhorayyyymmdd());
                    saveFecFin("");
                    saveDescripcion("");
                    saveDescripcionEN("");
                    saveShowModal(true);
                }else{
                    // Modificar. Si que existe el documento
                    saveAccion("modificar");
                    saveShowModal(true);
                }
            }else if(rows.length == 0){
                alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${confPantalla.planificadorpr.controles.noselected.title}`, {autoClose: true}); 
            }
        }else if(registroClonar != null){
            // Clonamos desde la tabla de planificación                        
            saveAccion("clonar");
            saveIdClonar(registroClonar.id)            
            saveCentro(registroClonar.idcen);
            saveFecIni(registroClonar.fecinidata);
            saveFecFin(registroClonar.fecfindata);
            saveDescripcion(registroClonar.descripcion_es);
            saveDescripcionEN(registroClonar.descripcion_en);
            saveShowModal(true);
        }
    }

    const enviarDocumentos = async() =>{

        saveShowModal(false);
        saveEnviandoDocumento(true);

        let idsSeleccionados = [];
        let repeticiones = [];

        // Guardamos los datos de las plantillas seleccionadas en la tabla
        let table = $("#tableplantillaspr").DataTable();
        const rows = table.rows( { selected: true } ).data();
        rows.map(row => {
            let rep = 0;            
            for(let i=0; i<datosTablaRepeticiones.length; i++){
                if( datosTablaRepeticiones[i].id == row.id ){
                    rep = datosTablaRepeticiones[i].repeticiones;
                }
            }
            let aux = { idlinea: row.id, repeticiones: rep };
            idsSeleccionados.push(aux)            
        })        
        datosTablaRepeticiones.map(rep => repeticiones.push(rep));        

        // Llamada
        const data = new FormData();      
        data.append("validacion", apiKey());
        data.append("ids", JSON.stringify(idsSeleccionados));
        if(accion == "crear" || accion == "clonar"){
            // No existe el documento de planificación
            data.append("accion", accion);
            data.append("centro", centro);
            data.append("fecIni", fecIni);
            data.append("fecFin", fecFin);
            data.append("descripcion", descripcion);
            data.append("descripcionen", descripcionEN);
            if(accion == "clonar"){
                data.append("idclonar", idClonar);
            }
        }else if(accion == "modificar"){
            // Existe el documento de planificación
            data.append("accion", accion);            
            data.append("idPlanCurso", idPlantillaPlanificador);
        }

        const respuesta = await axiosPost(getApiUrl(window.location.origin)+confPantalla.planificadorpr.controles.actionsdoc, data, source2.token);
        
        if( typeof respuesta.data == "string"){
            alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${respuesta.data}`, {autoClose: true}); 
        }else{
            if(accion == "crear" || accion == "clonar"){  
                // Quitamos la selección
                // saveIdPlantillaPlanificador(null);
                await saveIdPlantillaPlanificador(respuesta.data[0]);
                await saveDatosTablaEdicion([]);              
                await getPlantillasPlanificador();
                selectAndGoDataTablesReg(respuesta.data[0]);                
            }
            await getDatosTablaEdicion(respuesta.data[0]);             
            alertService.success(`<i class="fal fa-check-circle mr-1"></i>${confPantalla.planificadorpr.controles.success.title}`, {autoClose: true});
        }

        saveEnviandoDocumento(false);

    }
    
    const selectAndGoDataTablesReg = (idReg) => {
        let table = $("#tableplantillasplanificadorpr").DataTable();
        let rows = table.rows().data();                
        // Ir a la página del registro                 
        let info = table.page.info();                 
        const regs = table.rows( {search:'applied'} ).data();
        let pos = 0;
        for( let i = 0; i < regs.length; i++ ){
            if( regs[i].id == idReg ){
                pos = i;
                break;
            }
        }
        if( pos > 0 ){
            table.page( Math.floor( pos / info.length ) ).draw( false );
        }
        // Seleccionar el registro                
        for(let i=0; i < rows.length; i++){
            if( rows[i].id == idReg ){
                // saveTitleEditar(rows[i].descripcion+" - "+rows[i].fecini+" - "+rows[i].fecfin)
                saveTitleEditar(rows[i].tituloedi)
                table.row(':eq('+i+')', { page: 'current' }).select();                       
            }
        }
        // Ponerle la clase                          
        let tr = document.querySelector("table#tableplantillasplanificadorpr #\\#row-"+idReg);                
        if(tr){                    
            tr.classList.add("selected");
        }
    }

    const reorderRowsTablaEdicion = async (e, diff, edit) => {        
        
        if( !reordenandoLineas ){
            reordenandoLineas = true;
            let datos = [];

            diff.map(d=> {        
                let dataAux = { id: d.node.id.split("#row-")[1], old: d.oldPosition+1, new: d.newPosition+1};         
                datos.push(dataAux);
            })
            const data = new FormData();    
            data.append("validacion", apiKey());
            data.append("datos", JSON.stringify(datos));
            const respuesta = await axiosPost(getApiUrl(window.location.origin)+confPantalla.planificadorpr.controles.tablaedi.datareorder, data, source3.token);
            if( typeof respuesta.data == "string"){
                alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${confPantalla.planificadorpr.controles.tablaedi.reordererror.title}`, {autoClose: true}); 
            }else{                
                await getDatosTablaEdicion(idPlantillaPlanificador); 
                // saveReloadKpi(reloadKpi => reloadKpi+1)           
                alertService.success(`<i class="fal fa-check-circle mr-1"></i>${confPantalla.planificadorpr.controles.tablaedi.reordersuccess.title}`, {autoClose: true});
            }
            reordenandoLineas = false;
        }
    }

    const orderByDate = async() => {                
        const data = new FormData();    
        data.append("validacion", apiKey());
        data.append("iddoc", idPlantillaPlanificador);
        const respuesta = await axiosPost(getApiUrl(window.location.origin)+confPantalla.planificadorpr.controles.tablaedi.datareorderdate, data, source4.token);
        if( typeof respuesta.data == "string"){
            alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${confPantalla.planificadorpr.controles.tablaedi.reordererror.title}`, {autoClose: true}); 
        }else{                
            await getDatosTablaEdicion(idPlantillaPlanificador);             
            alertService.success(`<i class="fal fa-check-circle mr-1"></i>${confPantalla.planificadorpr.controles.tablaedi.reordersuccess.title}`, {autoClose: true});
        }
    }

    const handleChangeCentro = (selectedItem, nameOfComponent) => {
        if( selectedItem ){
            saveCentro(selectedItem.value) 
        }else{
            saveCentro("")
        }
    }

    const handleInput = (e, control) =>{
        switch(control){
            case 0:
                saveFecIni(e.target.value);
                break;
            case 1:
                saveFecFin(e.target.value);
                break;
            case 2:
                saveDescripcion(e.target.value);
                break; 
            case 3:
                saveDescripcionEN(e.target.value);
                break;            
        }
    }
    

    return(
        <div className="App wrapper">   
        {
            confPantalla !== undefined
            ?
                <Fragment>
                    <Alert fondo={true} />
                    <Menusup 
                        props = { props }
                    />
                    <Sidebar 
                        props = { props }
                    />
                </Fragment>
            :
            null
        } 
        <div className="content-wrapper">
            <div className="content-header">
            </div>
            <section className="content">                
                {
                    !tieneNivel 
                    ?
                        <PermissionRefused
                            traduction={confPantalla.config.trad.components.permissionrefused} 
                        />
                    :
                    <Fragment>
                    {
                        loadedConf === true && confPantalla !== undefined
                        ?
                        <div>                                        
                        {/* <Accordion defaultActiveKey={ cookies.get('defaultActiveKeyplanificadorpr') ? cookies.get('defaultActiveKeyplanificadorpr') : ""  }  >                                                                                                
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => toogleAcordeon('0', "acordeonPlantillas")} className={ acordeonPlantillas == "0" ? "activeAcordeon" : "" }>
                                    <i className={ confPantalla.planificadorpr.controles.plantillas.section.icono } ></i> { confPantalla.planificadorpr.controles.plantillas.section.titulo }
                                    {
                                        acordeonPlantillas == "0"
                                        ?
                                            <i className={"fal fa-chevron-up float-right fa-2x"} ></i>
                                        :
                                            <i className={"fal fa-chevron-down float-right fa-2x"} ></i>
                                    }
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0" className={"slowAcordeon"} >
                                    <Card.Body> */}
                            <Section titulo={ confPantalla.planificadorpr.section.titulo } icono={ confPantalla.planificadorpr.section.icono } col={ confPantalla.planificadorpr.section.col } idPrin={ formularioPrin.id } wiki={ confPantalla.planificadorpr.section.wiki } >                            
                                        <MiDataTablePlanificadorPr 
                                            id={ "tableplantillaspr" }
                                            columnas={ confPantalla.planificadorpr.controles.plantillas.tabla.columns }
                                            data={ datosTablaPlantillas }
                                            buttons={ confPantalla.planificadorpr.controles.plantillas.tabla.buttons }
                                            ordering={ confPantalla.planificadorpr.controles.plantillas.tabla.ordering }
                                            order={ confPantalla.planificadorpr.controles.plantillas.tabla.order }
                                            searching={ confPantalla.planificadorpr.controles.plantillas.tabla.searching }
                                            paging={ confPantalla.planificadorpr.controles.plantillas.tabla.paging }
                                            select={ confPantalla.planificadorpr.controles.plantillas.tabla.select }
                                            columnactions={ confPantalla.planificadorpr.controles.plantillas.tabla.title }
                                            traduction={ confPantalla.config.trad.components.datatable }
                                            linkColumns = { confPantalla.planificadorpr.controles.plantillas.tabla.linkColumns }
                                            pagelength = { confPantalla.planificadorpr.controles.plantillas.tabla.pagelength }
                                            grouping={ confPantalla.planificadorpr.controles.plantillas.tabla.grouping}
                                            loading={ loadingTablaPlantillas }
                                            adjuntosplegados={ confPantalla.planificadorpr.controles.plantillas.tabla.adjuntosplegados }
                                            functionAfterSelect={null}
                                        />
                                        {
                                        datosTablaPlantillas.length > 0
                                            ?
                                                <Fragment>
                                                    <Button 
                                                        clases={ "col-12 mt-2 mb-4" }
                                                        color={ "success" }  
                                                        type="button" 
                                                        icono={ "" }   
                                                        id="btncalcplan"     
                                                        texto={ confPantalla.planificadorpr.controles.btncalcular.title }   
                                                        accion={ planificar } 
                                                        disabled={ false }
                                                    />                                                                        
                                                </Fragment>
                                            :
                                                null
                                        }
                                        {   enviandoDocumento 
                                            ? 
                                                <div className='mt-4' style={{height:50, marginTop:5, marginBottom:10, textAlign: "center"}} >
                                                    <Spinner animation="border" />
                                                </div>
                                            :
                                            null
                                        }                                        
                                        {
                                            loadingTablaPlantillas || loadingTablaPlantillasPlanificador
                                            ?   null
                                            :
                                            <div className="mt-3 text-center">
                                                <div 
                                                    className={!idPlantillaPlanificador  ? 'container-fluid containerSelectCrear mb-2' : 'container-fluid containerSelectEditar mb-2' }
                                                    style={{padding: "10px 10px 10px 10px"}}
                                                >
                                                        {
                                                            !idPlantillaPlanificador
                                                            ?
                                                                <h4 className='pl-3'>
                                                                    {confPantalla.planificadorpr.controles.modo.crear.title}
                                                                </h4>
                                                            :

                                                                <h4 className='pl-3'>
                                                                    {/* {confPantalla.planificadorpr.controles.modo.editar.title} */}
                                                                    <span dangerouslySetInnerHTML={stringToHtml(titleEditar)} />
                                                                </h4>                                                                    
                                                        }
                                                </div>

                                            </div>
                                        }
                                        <MiDataTablePlantillasPlanificador 
                                            estadoForm= { "" }
                                            formulario={ {id:0} }
                                            id={"tableplantillasplanificadorpr"}
                                            columnas={confPantalla.planificadorpr.controles.plantillasplan.datatable.columns}
                                            data={datosTablaPlantillasPlanificador}
                                            buttons={confPantalla.planificadorpr.controles.plantillasplan.datatable.buttons}
                                            ordering={confPantalla.planificadorpr.controles.plantillasplan.datatable.ordering}
                                            order={confPantalla.planificadorpr.controles.plantillasplan.datatable.order}
                                            searching={confPantalla.planificadorpr.controles.plantillasplan.datatable.searching}
                                            paging={confPantalla.planificadorpr.controles.plantillasplan.datatable.paging}
                                            select={confPantalla.planificadorpr.controles.plantillasplan.datatable.select}
                                            pagelength={confPantalla.planificadorpr.controles.plantillasplan.datatable.pagelength}
                                            saveLoadingTable={ saveLoadingTablaPlantillasPlanificador }
                                            loadingTable={loadingTablaPlantillasPlanificador}
                                            columnactions={confPantalla.planificadorpr.controles.plantillasplan.datatable.columnactions.title}
                                            linkColumns={confPantalla.planificadorpr.controles.plantillasplan.datatable.linkColumns}
                                            traduction={confPantalla.config.trad.components.datatable}
                                            functionAfterSelect={selectPlantillaPlanificador}
                                            // selectId={{id:idPlantillaPlanificador}}
                                            selectId={{id:0}}
                                            saveTitleEditar={saveTitleEditar}
                                            planificar={planificar}                                        
                                        />                                        
                                        {
                                            idPlantillaPlanificador
                                            // idPlantillaPlanificador && !loadingTablaEdicion
                                            ?
                                                <Fragment>
                                                    <Button 
                                                        clases={ "btn btn-flat btn-outline-secondary mt-2" }
                                                        color={ " " }  
                                                        type="button" 
                                                        icono={ "far fa-sort-amount-down-alt" }   
                                                        id="orderByDate"     
                                                        texto={""}   
                                                        accion={ orderByDate } 
                                                        disabled={ datosTablaEdicion.length == 0 || loadingTablaPlantillas || loadingTablaPlantillasPlanificador || loadingTablaEdicion || loadingRepeticiones || enviandoDocumento ? true : false }
                                                    /> 
                                                    <MiDataTableEdicion 
                                                        estadoForm= { "" }
                                                        formulario={ {id:0} }
                                                        id={"tableedicion"}
                                                        columnas={confPantalla.planificadorpr.controles.tablaedi.datatable.columns}
                                                        data={datosTablaEdicion}
                                                        saveData={saveDatosTablaEdicion}
                                                        buttons={confPantalla.planificadorpr.controles.tablaedi.datatable.buttons}
                                                        ordering={confPantalla.planificadorpr.controles.tablaedi.datatable.ordering}
                                                        order={confPantalla.planificadorpr.controles.tablaedi.datatable.order}
                                                        searching={confPantalla.planificadorpr.controles.tablaedi.datatable.searching}
                                                        paging={confPantalla.planificadorpr.controles.tablaedi.datatable.paging}
                                                        select={confPantalla.planificadorpr.controles.tablaedi.datatable.select}
                                                        pagelength={confPantalla.planificadorpr.controles.tablaedi.datatable.pagelength}                                                                
                                                        columnactions={confPantalla.planificadorpr.controles.tablaedi.datatable.columnactions.title}
                                                        linkColumns={confPantalla.planificadorpr.controles.tablaedi.datatable.linkColumns}
                                                        rowreorder={confPantalla.planificadorpr.controles.tablaedi.datatable.rowreorder}
                                                        traduction={confPantalla.config.trad.components.datatable}
                                                        selectest={confPantalla.planificadorpr.controles.tablaedi.controles.idest}
                                                        fecPrev={confPantalla.planificadorpr.controles.tablaedi.controles.fec2}
                                                        optionselectest={optionsEst}
                                                        getDatosTablaEdicion={getDatosTablaEdicion}
                                                        functionAfterReorder={reorderRowsTablaEdicion}
                                                        confPantalla={confPantalla}
                                                        saveLoading={saveLoadingTablaEdicion}
                                                        loadingTable={loadingTablaEdicion}
                                                        propiedades={props}
                                                    />
                                                </Fragment>                                            
                                            :
                                                null
                                        }
                            </Section>                                                                               
                                    {/* </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>                        */}
                        </div>
                            
                        :
                            <LoadingPage />
                        }
                    {
                        showModal
                        ?
                        <Modal
                            show={showModal}
                            onHide={ () => saveShowModal(false)}                            
                            aria-labelledby="example-custom-modal-styling-title"
                            centered={true}                    
                            dialogClassName="modal-90w"                                             
                        > 
                                <Modal.Header closeButton>
                                    {
                                        accion == "crear"
                                        ? <h4>{confPantalla.planificadorpr.controles.modal.headercrear.title}</h4>
                                        : accion == "clonar"
                                        ? <h4>{confPantalla.planificadorpr.controles.modal.headerclonar.title}</h4>
                                        : <h4>{confPantalla.planificadorpr.controles.modal.headereditar.title}</h4>
                                    }                                    
                                </Modal.Header>                         
                                <Modal.Body>
                                    {
                                        accion == "crear" || accion == "clonar"
                                        ?
                                            <Fragment>
                                                <FormRow>
                                                    <MiSelect  
                                                        estadoForm=  { "" }
                                                        // val=         { centroActivo != "0" && centroActivo != "" && centro == "" ? centroActivo : centro }   
                                                        val=         { centro }   
                                                        loading=     { loadReg ? true : false }
                                                        id=          { confPantalla.planificadorpr.controles.modal.controles.idcen.id }    
                                                        name=        { confPantalla.planificadorpr.controles.modal.controles.idcen.name }   
                                                        label=       { confPantalla.planificadorpr.controles.modal.controles.idcen.label }    
                                                        placeholder= { confPantalla.planificadorpr.controles.modal.controles.idcen.placeholder }
                                                        col=         { confPantalla.planificadorpr.controles.modal.controles.idcen.col } 
                                                        isClearable= { confPantalla.planificadorpr.controles.modal.controles.idcen.isclearable }
                                                        isSearchable={ confPantalla.planificadorpr.controles.modal.controles.idcen.issearchable }
                                                        dataType=    { confPantalla.planificadorpr.controles.modal.controles.idcen.datatype }
                                                        data=        { confPantalla.planificadorpr.controles.modal.controles.idcen.data }
                                                        required=    { confPantalla.planificadorpr.controles.modal.controles.idcen.required } 
                                                        disabled=    { confPantalla.planificadorpr.controles.modal.controles.idcen.disabled }
                                                        // readOnly =   { centroActivo != "0" && centroActivo != "" ? "readonly" : confPantalla.planificador.controles.modal.controles.idcen.readonly }
                                                        readOnly =   { confPantalla.planificador.controles.modal.controles.idcen.readonly }
                                                        disablethisid={false}
                                                        formulario= { {id:0} }   
                                                        change= { handleChangeCentro }
                                                    />                                        
                                                    <FormInput                                                        
                                                        estadoForm= { "" }
                                                        value=      { fecIni }
                                                        loading=    { loadReg ? true : false }
                                                        id=         { confPantalla.planificadorpr.controles.modal.controles.fec1.id }   
                                                        type=       { confPantalla.planificadorpr.controles.modal.controles.fec1.type }  
                                                        name=       { confPantalla.planificadorpr.controles.modal.controles.fec1.name }   
                                                        label=      { confPantalla.planificadorpr.controles.modal.controles.fec1.label }                                                       
                                                        placeholder={ confPantalla.planificadorpr.controles.modal.controles.fec1.placeholder }    
                                                        col=        { confPantalla.planificadorpr.controles.modal.controles.fec1.col } 
                                                        required=   { confPantalla.planificadorpr.controles.modal.controles.fec1.required }
                                                        readonly=   { confPantalla.planificadorpr.controles.modal.controles.fec1.readonly }                                                
                                                        disabled=   { confPantalla.planificadorpr.controles.modal.controles.fec1.disabled }
                                                        change=     { e => handleInput(e,0) }
                                                        onBlur=     {null} 
                                                    />
                                                    <FormInput                                                        
                                                        estadoForm= { "" }
                                                        value=      { fecFin }
                                                        loading=    { loadReg ? true : false }
                                                        id=         { confPantalla.planificadorpr.controles.modal.controles.fec3.id }   
                                                        type=       { confPantalla.planificadorpr.controles.modal.controles.fec3.type }  
                                                        name=       { confPantalla.planificadorpr.controles.modal.controles.fec3.name }   
                                                        label=      { confPantalla.planificadorpr.controles.modal.controles.fec3.label }                                                       
                                                        placeholder={ confPantalla.planificadorpr.controles.modal.controles.fec3.placeholder }    
                                                        col=        { confPantalla.planificadorpr.controles.modal.controles.fec3.col } 
                                                        required=   { confPantalla.planificadorpr.controles.modal.controles.fec3.required }
                                                        readonly=   { confPantalla.planificadorpr.controles.modal.controles.fec3.readonly }                                                
                                                        disabled=   { confPantalla.planificadorpr.controles.modal.controles.fec3.disabled }
                                                        change=     { e => handleInput(e,1) }
                                                        onBlur=     {null} 
                                                    />
                                                </FormRow>                                   
                                                <FormRow>
                                                    <FormInput                                                        
                                                        estadoForm= { "" }
                                                        value=      { descripcion }
                                                        loading=    { loadReg ? true : false }
                                                        id=         { confPantalla.planificadorpr.controles.modal.controles.descripcion.id }   
                                                        type=       { confPantalla.planificadorpr.controles.modal.controles.descripcion.type }  
                                                        name=       { confPantalla.planificadorpr.controles.modal.controles.descripcion.name }   
                                                        label=      { confPantalla.planificadorpr.controles.modal.controles.descripcion.label }                                                       
                                                        placeholder={ confPantalla.planificadorpr.controles.modal.controles.descripcion.placeholder }    
                                                        col=        { confPantalla.planificadorpr.controles.modal.controles.descripcion.col } 
                                                        required=   { confPantalla.planificadorpr.controles.modal.controles.descripcion.required }
                                                        readonly=   { confPantalla.planificadorpr.controles.modal.controles.descripcion.readonly }                                                
                                                        disabled=   { confPantalla.planificadorpr.controles.modal.controles.descripcion.disabled }
                                                        change=     { e => handleInput(e,2) }
                                                        onBlur=     {null} 
                                                    />
                                                </FormRow>
                                                <FormRow>
                                                    <FormInput                                                        
                                                        estadoForm= { "" }
                                                        value=      { descripcionEN }
                                                        loading=    { loadReg ? true : false }
                                                        id=         { confPantalla.planificadorpr.controles.modal.controles.descripcion_en.id }   
                                                        type=       { confPantalla.planificadorpr.controles.modal.controles.descripcion_en.type }  
                                                        name=       { confPantalla.planificadorpr.controles.modal.controles.descripcion_en.name }   
                                                        label=      { confPantalla.planificadorpr.controles.modal.controles.descripcion_en.label }                                                       
                                                        placeholder={ confPantalla.planificadorpr.controles.modal.controles.descripcion_en.placeholder }    
                                                        col=        { confPantalla.planificadorpr.controles.modal.controles.descripcion_en.col } 
                                                        required=   { confPantalla.planificadorpr.controles.modal.controles.descripcion_en.required }
                                                        readonly=   { confPantalla.planificadorpr.controles.modal.controles.descripcion_en.readonly }                                                
                                                        disabled=   { confPantalla.planificadorpr.controles.modal.controles.descripcion_en.disabled }
                                                        change=     { e => handleInput(e,3) }
                                                        onBlur=     {null} 
                                                    />
                                                </FormRow>
                                            </Fragment>
                                        :
                                            null
                                    }
                                    {
                                        accion != "clonar"
                                        ?
                                            <MiDataTableRepeticiones 
                                                    estadoForm= { "" }
                                                    formulario={ {id:0} }
                                                    id={"tablerepeticiones"}
                                                    columnas={confPantalla.planificadorpr.controles.repeticiones.columns}
                                                    data={datosTablaRepeticiones}
                                                    saveData={saveDatosTablaRepeticiones}
                                                    buttons={confPantalla.planificadorpr.controles.repeticiones.buttons}
                                                    ordering={confPantalla.planificadorpr.controles.repeticiones.ordering}
                                                    order={confPantalla.planificadorpr.controles.repeticiones.order}
                                                    searching={confPantalla.planificadorpr.controles.repeticiones.searching}
                                                    paging={confPantalla.planificadorpr.controles.repeticiones.paging}
                                                    select={confPantalla.planificadorpr.controles.repeticiones.select}
                                                    pagelength={confPantalla.planificadorpr.controles.repeticiones.pagelength}
                                                    saveLoadingTable={ saveLoadingRepeticiones }
                                                    loadingTable={ loadingRepeticiones }
                                                    columnactions={confPantalla.planificadorpr.controles.repeticiones.columnactions.title}
                                                    linkColumns={confPantalla.planificadorpr.controles.repeticiones.linkColumns}
                                                    traduction={confPantalla.config.trad.components.datatable}
                                                    functionClickTd={null}                                        
                                            />
                                        :
                                            null
                                    }
                                    <Button 
                                        clases={ "col-12 mt-2" }
                                        color={ "success" }  
                                        type="button" 
                                        icono={ "" }   
                                        id="btncalcplan"     
                                        texto={ 
                                            accion == "crear"
                                            ? confPantalla.planificadorpr.controles.btncrear.title
                                            : accion == "clonar"
                                            ? confPantalla.planificadorpr.controles.btnclonar.title
                                            : confPantalla.planificadorpr.controles.btnmodificar.title

                                         }   
                                        accion={ enviarDocumentos } 
                                        disabled={ 
                                            (centro == "" || descripcion == "" || descripcionEN == "" || fecIni == "" || fecIni == "0000-00-00" || fecFin == "" || fecFin == "0000-00-00" ) && (accion == "crear" || accion == "clonar" ) 
                                            ? true 
                                            :  false }
                                    />                                    
                                    <p className="font-italic text-center">{ confPantalla.planificadorpr.controles.modal.required.title }</p> 
                                 
                                </Modal.Body>                            
                            </Modal>
                        :
                            null
                    }
                    </Fragment>
                }
                
            </section>                                    
        </div>
        <Footer />        
        </div>

    );

}
export default PlanificadorPr