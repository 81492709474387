import React, { useEffect, useContext, useState } from 'react';
import $ from 'jquery';
import swal from 'sweetalert';
import DataTable from 'datatables.net';
import { LoginContext } from '../../hooks/context/LoginContext';
import axios from 'axios';

import Menusup from '../../componentes/menus/menusup/Menusup';
import Sidebar from '../../componentes/menus/sidebar/Sidebar';
import Footer from '../../componentes/footer/Footer';
import Section from '../../componentes/ui/Section';
import MiDataTableAgentes from './MiDataTableAgentes';
import MiDataTableCrewList from './MiDataTableCrewList';
import PermissionRefused from '../../componentes/ui/PermissionRefused';
import LoadingPage from '../../componentes/ui/LoadingPage';
import Button from '../../componentes/ui/Button';
import { Spinner } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import MiSelect from '../../componentes/formulario/MiSelect';
import MiSelectMultiple from '../../componentes/formulario/MiSelectMultiple';
import FormInput from '../../componentes/formulario/FormInput';
import FormRow from '../../componentes/formulario/FormRow';
import { Accordion, Card } from "react-bootstrap";
import Firma from '../../componentes/formulario/Firma';


import { alertService } from '../../services/alertservice';
import { Alert } from '../../componentes/ui/Alert';

import useLogicForm from '../../hooks/useLogicForm';

import Cookies from 'universal-cookie';

import { getApiUrl, axiosPost, getPantDocFromNivel, getAhorayyyymmdd, MiReplaceAll, getTitle, stringToHtml } from '../../helpers/helperApp';

import { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import ShowFirmas from '../../componentes/formulario/ShowFirmas';


$.DataTable = DataTable;
const resetFormCab = { id: "", tipo: "", idcentro: "", numeroviaje: "", puertosalida: "", fechasalida: "", ultimaescala: ""};
const resetFormAgente = {id : "", nom: "", rel_agentes_tip : [], pob: "", fec1: "", web: "", fec2: "", idpais: "", pro: "", txt1: "", txt2: ""};

function CrewList(props){

    const cookies = new Cookies();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const source2 = CancelToken.source();
    const source3 = CancelToken.source();
    const source4 = CancelToken.source();

    // ***** Context *****
    const { validateSesion, socket, confApp, saveConfApp, getConfApp, apiKey, centroActivo } = useContext(LoginContext);   
    
    // ***** State para la configuración de la pantalla *****
    const [ confPantalla, saveConfPantalla ] = useState(confApp)
    const [ loadedConf, saveLoadedConf ] = useState(false);
    const [ tieneNivel, saveTieneNivel ] = useState(true);    
    // Tabla CrewList
    const [ datosTablaDocsCrewList, saveDatosTablaDocsCrewList ] = useState([]);
    const [ selectedDocCrewList, saveselectedDocCrewList ] = useState(null)
    const [ loadingTablaDocsCrewList, saveLoadingTablaDocsCrewList ] = useState(false);
    // Cbo Agentes
    const [ optionsCboAgentes, saveOptionsCboAgentes ] = useState([]);
    const [ selectedAgente, saveSelectedAgente ] = useState(null);
    const [ disableAgentes, saveDisableAgentes ] = useState([]);
    const [ forceReloadAgente, saveForceReloadAgente ] = useState(0);
    // Tabla Agentes
    const [ datosTablaAgentes, saveDatosTablaAgentes ] = useState([]);
    const [ loadingTablaAgentes, saveLoadingTablaAgentes ] = useState(false);
    // Modal crear crewlist
    const [ showModalNewCrewList, saveShowModalNewCrewList ] = useState(false);
    const [ formularioCabecera, saveFormularioCabecera ] = useState({
        id: "",
        tipo: "",
        idcentro: "",
        numeroviaje: "",
        puertosalida: "",
        fechasalida: "",
        ultimaescala: ""
    });
    const [ loadingCreateCrewList, saveLoadingCreateCrewList ] = useState(false);
    // Modal crear agente
    const [ showModalNewAgente, saveShowModalNewAgente ] = useState(false);
    // Otros 
    const [ titleEditar, saveTitleEditar ] = useState("");
    const [ ediAgente, saveEdiAgente ] = useState({
        id: "",
        nom: "",
        rel_agentes_tip : [],
        pob: "",
        fec1: "",
        web: "",
        fec2: "",
        idpais: "",
        pro: "", 
        txt1: "",
        txt2: ""
    });
    const [ agenteDuplicado, saveAgenteDuplicado ] = useState(null);
    const [ loadingSaveAgente, saveLoadingSaveAgente ] = useState(false);
    const [ accionClonar, saveAccionClonar ] = useState(false);
    const [ documentoDuplicado, saveDocumentoDuplicado ] = useState(null);
    const [ ejecutando, saveEjecutando ] = useState(false);
    const [ showFirma, saveShowFirma ] = useState(false);
    const [ docEjecutable, saveDocEjecutable ] = useState(true);

    // ***** Custom Hook - Lógica de los formularios *****
    const { registrosPrin, saveRegistrosPrin, formularioPrin, saveFormularioPrin, adjuntosPrin, saveAdjuntosPrin, adjuntosExistentesPrin, saveAdjuntosExistentesPrin, registrosHijo,  formularioHijo, saveFormularioHijo, adjuntosHijo, saveAdjuntosHijo, adjuntosExistentesHijo, saveAdjuntosExistentesHijo, handleInputForm, handleLastReg, handleEditRegPrin, loadingTable, saveLoadingTable, loadReg, regIniciales, handleEditRegHijo, handleDeleteItemPrin, handleDeleteItemHijo, handleSubmitPrin, handleSubmitHijo, resetFormPrin, resetFormHijo, handleSelectFormPrin, handleSelectFormHijo, handleSelectFormPrinAndSetValue, handleSelectFormHijoAndSetValue, handleClonePrin, handleCloneHijo } = useLogicForm();

    useEffect( () => {        
        
        // ***** Validamos que el usuario esté logueado sino lo enviamos al login*****
        validateSesion(props)
            .then( (respuesta) => {
                if( confPantalla === undefined ){
                    return getConfApp();
                }else{ 
                    document.title = getTitle(confPantalla.config.trad, confPantalla.crewlist.title);                    
                    if( respuesta == 1 ){
                        saveTieneNivel(true); 
                        init();                                                                                                       
                    }
                    else if( respuesta == 0 ){
                        saveTieneNivel(false);
                    }                   
                    saveLoadedConf(true);        
                }
            })
            .then( (respuesta) => {
                if( respuesta !== undefined  ){
                    saveConfPantalla(respuesta.msg);
                    saveConfApp(respuesta.msg);             
                }
            })          

        // Liberamos todos los registros al salir de la pantalla
        return () =>{
            if( socket != null ){
                socket.emit("salirPantalla", { "idusu": cookies.get('id'), "tabla": "undefined" })
            }
                document.querySelector("body").classList.remove("sidebar-open");

                // Token cancelación axios al desmontar componentes
                source.cancel();
                source2.cancel();
                source3.cancel();
                source4.cancel();
        } 

    },[confPantalla, formularioHijo.id, centroActivo])


    const handleChangeAgente = (selectedItem, nameOfComponent) => {
        if( selectedItem ){            
            saveSelectedAgente(selectedItem.value);  
        }else{
            saveSelectedAgente(null);
        }
    }

    const handleSelectCabecera = (selectedItem, nameOfComponent) => {
        if( selectedItem ){
            saveFormularioCabecera({ ...formularioCabecera, [nameOfComponent.name]:selectedItem.value })  
        }else{
            saveFormularioCabecera({ ...formularioCabecera, [nameOfComponent.name]:"" })
        }
    }

    const handleInputCabecera = (e) => {
        const { name, value } = e.target;
        saveFormularioCabecera({ ...formularioCabecera, [name]:value })        
    }    

    const editCabecera = (row) => { 
        saveLoadingCreateCrewList(false);
        saveFormularioCabecera({id: row.id, tipo: row.tipoform, idcentro: row.idcen, numeroviaje: row.cod, puertosalida: row.nom1, fechasalida: row.fec1form, ultimaescala: row.nom1_en});
        saveAccionClonar(false);
        saveDocumentoDuplicado(null);
        saveShowModalNewCrewList(true);
    }

    const loadOptionsCbo = async (fuente, opcion, options, saveOptions) =>{             
        const data = new FormData();
        data.append("validacion", apiKey());
        if( opcion.includes("usu_actual") ){
            opcion = MiReplaceAll(opcion, "usu_actual", cookies.get('id'));
        }
        data.append(fuente, opcion);                    
        
        let url = null;
        if( fuente == "filter" ){
            url = getApiUrl(window.location.origin)+'/filters/getFilter';
        }else{
            url = getApiUrl(window.location.origin)+'/app/getDataCbo';
        }
        const respuesta = await axios.post(url, data);        
        saveOptions(respuesta.data)            

    }

    const init = () => {
        getDocsCrewList();        
        loadOptionsCbo(confPantalla.crewlist.controles.agentes.datatype, confPantalla.crewlist.controles.agentes.data, optionsCboAgentes, saveOptionsCboAgentes);          
    }

    const getDocsCrewList = async() => {
        saveLoadingTablaDocsCrewList(true);

        const data = new FormData();
        data.append("validacion", apiKey());
        const respuesta = await axiosPost(getApiUrl(window.location.origin)+ confPantalla.crewlist.controles.crewlist.getdocscrewlist, data, source.token);
        
        if( typeof respuesta.data == "string" ){
            alertService.error(`<i class="fal fa-check-circle mr-1"></i>${respuesta.data}`, {autoClose: false});
        }else if( typeof respuesta.data == "object" ){
            saveDatosTablaDocsCrewList(respuesta.data);   
        }

        saveLoadingTablaDocsCrewList(false);
    }

    const selectDocscrewlist = async (idRow, id, datos, selectedRows) => {
        saveLoadingTablaDocsCrewList(true);
        let trs = document.querySelectorAll("table#docscrewlist tr.selected");
        for( let i = 0; i < trs.length; i++ ){
            if(trs[i].id != idRow){
                trs[i].classList.remove("selectedRow");
                trs[i].classList.remove("selected");
            }
        }

        if(selectedRows.length == 0){
            await saveFormularioCabecera(resetFormCab);            
            await saveselectedDocCrewList(null);
            await saveDatosTablaAgentes([]);
            await saveDisableAgentes([]);
            saveDocEjecutable(true);
        }else{
            if(selectedRows[0].idest == 11){
                saveDocEjecutable(true);
            }else{                
                saveDocEjecutable(false);
            }
            await saveFormularioCabecera({id: selectedRows[0].id, tipo: selectedRows[0].tipoform, idcentro: selectedRows[0].idcen, numeroviaje: selectedRows[0].cod, puertosalida: selectedRows[0].nom1, fechasalida: selectedRows[0].fec1form, ultimaescala: selectedRows[0].nom1_en});            
            await saveselectedDocCrewList(selectedRows[0].id);            
            await getLinesCrewList(selectedRows[0].id);
            saveTitleEditar(selectedRows[0].centro+" - "+selectedRows[0].tipo+" - "+selectedRows[0].nom1);            
        }
        saveLoadingTablaDocsCrewList(false);
    }

    const getLinesCrewList = async(idDocCrewList) => {
        saveLoadingTablaAgentes(true);

        const data = new FormData();
        data.append("validacion", apiKey());
        data.append("iddoc", idDocCrewList);
        const respuesta = await axiosPost(getApiUrl(window.location.origin)+ confPantalla.crewlist.controles.tabagentes.getlinescrewlist, data, source.token);
        
        if( typeof respuesta.data == "string" ){
            alertService.error(`<i class="fal fa-check-circle mr-1"></i>${respuesta.data}`, {autoClose: false});
        }else if( typeof respuesta.data == "object" ){
            // Deshabilitar los agentes que ya están en la tabla             
            await saveDisableAgentes(respuesta.data.map((reg)=> reg.idage ));
            await saveSelectedAgente(null);
            saveDatosTablaAgentes(respuesta.data);
        }

        saveLoadingTablaAgentes(false);
    }

    const addAgente = async  () => {

        if(!selectedDocCrewList){
            // Crear documento y línea
            saveFormularioCabecera(resetFormCab);
            saveAccionClonar(false);
            saveDocumentoDuplicado(null);
            saveShowModalNewCrewList(true);
        }else{
            // Crear línea 
            await createCrewListLine(selectedDocCrewList);            
        }        
    }

    const newAgente = () => {
        saveEdiAgente(resetFormAgente);
        saveSelectedAgente(null);
        saveAgenteDuplicado(null);
        saveShowModalNewAgente(true);
        saveLoadingSaveAgente(false);
    }

    const createCrewList = async () =>{
        let accion = "";
        saveLoadingCreateCrewList(true);

        const data = new FormData();
        data.append("validacion", apiKey());
        data.append("cabecera", JSON.stringify(formularioCabecera));
        const respuesta = await axiosPost(getApiUrl(window.location.origin)+ confPantalla.crewlist.controles.modaladdcrewlist.createcrewlist, data, source.token);
        
        // Si estamos editando realizamos acciones distintas que al crear
        formularioCabecera.id == "" ? accion = "crear" : accion = "editar";

        if( typeof respuesta.data == "string" ){
            alertService.error(`<i class="fal fa-check-circle mr-1"></i>${respuesta.data}`, {autoClose: false});
        }else if(respuesta.data.duplicado != undefined){
            // Error documento duplicado            
            saveDocumentoDuplicado(respuesta.data.duplicado[0]);            
        }else{
            await saveselectedDocCrewList(respuesta.data);                           
            // Recargar tabla crew List y seleccionar el id que acabamos de crear
            await getDocsCrewList();

            if(accion == "editar"){
                // Seleccionar el registro que acabamos de editar
                selectAndGoDataTablesReg(formularioCabecera.id);
                await getLinesCrewList(formularioCabecera.id);
            }else if(accion == "crear"){
                // Seleccionar el registro que acabamos de crear
                selectAndGoDataTablesReg(respuesta.data);
                // Crear línea
                await createCrewListLine(respuesta.data);            

            }
            saveShowModalNewCrewList(false);
        }
        saveLoadingCreateCrewList(false);
    }

    const createCrewListLine = async (idDoc) =>{
        saveLoadingCreateCrewList(true);

        const data = new FormData();
        data.append("validacion", apiKey());
        data.append("iddoc", idDoc);
        data.append("idage", selectedAgente);
        const respuesta = await axiosPost(getApiUrl(window.location.origin)+ confPantalla.crewlist.controles.tabagentes.createcrewlistline, data, source.token);
        
        if( typeof respuesta.data == "string" ){
            alertService.error(`<i class="fal fa-check-circle mr-1"></i>${respuesta.data}`, {autoClose: false});
        }else{
            // Recargar líneas
            await getLinesCrewList(idDoc);
        }

        saveLoadingCreateCrewList(false);
        saveShowModalNewCrewList(false);
    }

    const selectAndGoDataTablesReg = (idReg) => {
        let table = $("#docscrewlist").DataTable();
        let rows = table.rows().data();                
        // Ir a la página del registro                 
        let info = table.page.info();                 
        const regs = table.rows( {search:'applied'} ).data();
        let pos = 0;
        for( let i = 0; i < regs.length; i++ ){
            if( regs[i].id == idReg ){
                pos = i;
                break;
            }
        }
        if( pos > 0 ){
            table.page( Math.floor( pos / info.length ) ).draw( false );
        }
        // Seleccionar el registro                
        for(let i=0; i < rows.length; i++){
            if( rows[i].id == idReg ){                
                saveTitleEditar(rows[i].centro+" - "+rows[i].tipo+" - "+rows[i].nom1)                
                table.row(':eq('+i+')', { page: 'current' }).select();                       
            }
        }
        // Ponerle la clase                          
        let tr = document.querySelector("table#docscrewlist #\\#row-"+idReg);                
        if(tr){                    
            tr.classList.add("selected");
        }
    }

    const editAgente = (row) => {        
        saveEdiAgente({id : row.idage, nom: row.nominput, pob: row.pobform, fec1: row.fec1form, web: row.web, fec2: row.fec2form, idpais: row.idpais, pro: row.pro, txt1: row.txt1, txt2: row.txt2, rel_agentes_tip: row.rel_agentes_tip});
        saveAgenteDuplicado(null);
        saveShowModalNewAgente(true);
    }

    const saveAgente = async () => {

        saveLoadingSaveAgente(true);

        const data = new FormData();
        data.append("validacion", apiKey());
        data.append("crewlist", true);
        for (const [key, value] of Object.entries(ediAgente)) {                                 
            data.append(key, value);
        }
        const respuesta = await axiosPost(getApiUrl(window.location.origin)+ confPantalla.agentespr.padre.urls.submit, data, source.token);

        if( typeof respuesta.data == "string" ){
            alertService.error(`<i class="fal fa-check-circle mr-1"></i>${respuesta.data}`, {autoClose: false});
        }else if(respuesta.data.duplicado != undefined){
            // Error agente duplicado
            saveAgenteDuplicado(respuesta.data.duplicado[0]);            
        }else{
            await loadOptionsCbo(confPantalla.crewlist.controles.agentes.datatype, confPantalla.crewlist.controles.agentes.data, optionsCboAgentes, saveOptionsCboAgentes);
            await getLinesCrewList(selectedDocCrewList);
            saveForceReloadAgente(forceReloadAgente => forceReloadAgente+1);
            saveShowModalNewAgente(false);
        }

        saveLoadingSaveAgente(false);
    }

    const handleInputAgente = (e) => {
        const { name, value } = e.target;
        saveEdiAgente({ ...ediAgente, [name]:value })
    }

    const handleSelectAgente = (selectedItem, nameOfComponent) => {
        if( selectedItem ){
            saveEdiAgente({ ...ediAgente, [nameOfComponent.name]:selectedItem.value })  
        }else{
            saveEdiAgente({ ...ediAgente, [nameOfComponent.name]:"" })
        }
    }

    const handleSelectMultipleAgente = (selectedItem, nameOfComponent) => {
        let optionsSeleccionadas = [];
        let nombre = nameOfComponent.name.split("[]")[0];
        
        for(let i = 0; i < selectedItem.length; i++){
            optionsSeleccionadas.push(selectedItem[i].value);
        }
        
        if( selectedItem ){
            saveEdiAgente({ ...ediAgente, [nombre]:optionsSeleccionadas }) 
        }else{
            saveEdiAgente({ ...ediAgente, [nombre]:[] })
        }
    }


    const clonarDoc = async (rowData) =>{                
        saveFormularioCabecera({id: rowData.id, tipo: rowData.tipoform, idcentro: rowData.idcen, numeroviaje: rowData.cod, puertosalida: rowData.nom1, fechasalida: rowData.fec1form, ultimaescala: rowData.nom1_en});
        saveAccionClonar(true);
        saveDocumentoDuplicado(null);
        saveShowModalNewCrewList(true);
    }

    const clonarDocumento = async() => {
        saveDocumentoDuplicado(null);
        saveLoadingCreateCrewList(true);

        const data = new FormData();      
        data.append("validacion", apiKey());
        data.append("cabecera", JSON.stringify(formularioCabecera));
        const respuesta = await axios.post(getApiUrl(window.location.origin)+confPantalla.crewlist.controles.crewlist.clonedocumento.clone, data);

        if(typeof respuesta.data == "string"){
            // Error clonando
            alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${confPantalla.crewlist.controles.crewlist.clonedocumento.cloneerror.title}`, {autoClose: true}); 
        }else if(respuesta.data.duplicado != undefined){
            // Error documento duplicado            
            saveDocumentoDuplicado(respuesta.data.duplicado[0]);            
        }else{
            // Recargamos la tabla crewlist y sus líneas
            await getDocsCrewList();
            selectAndGoDataTablesReg(respuesta.data);
            await getLinesCrewList(respuesta.data);
            saveDocEjecutable(true);
            // Clonado
            alertService.success(`<i class="fal fa-check-circle mr-1"></i>${confPantalla.crewlist.controles.crewlist.clonedocumento.clonesuccess.title}`, {autoClose: true}); 
            saveShowModalNewCrewList(false);
        }
        saveLoadingCreateCrewList(false);
    }

    const ejecutarBtn = () => {
        swal({
            title: "",
            text: confPantalla.crewlist.controles.crewlist.ejecutar.swal.txttitle.title,
            icon: "warning",
            buttons: {
                cancel : {text: confPantalla.crewlist.controles.crewlist.ejecutar.swal.btncancelar.title, className:'btn btn-flat mr-1 btn-outline-secondary', visible: true},
                confirm : {text: confPantalla.crewlist.controles.crewlist.ejecutar.swal.btnaceptar.title, className:'btn btn-flat mr-1 btn-outline-success btnSwalSuccess'}
            } 
        }).then(confirmacion => {
            if(confirmacion){
                ejecutar()                
            }
        })
    }

    const ejecutar = async () => {
        saveEjecutando(true);

        const data = new FormData();      
        data.append("validacion", apiKey());
        data.append("cabecera", JSON.stringify(formularioCabecera));
        data.append("tripulantes", JSON.stringify(datosTablaAgentes));
        const respuesta = await axios.post(getApiUrl(window.location.origin)+confPantalla.crewlist.controles.crewlist.ejecutar.ejecutardoc, data);

        if(typeof respuesta.data == "string"){
            // Error 
            alertService.error(`<i class="fal fa-times-octagon mr-1"></i>${confPantalla.crewlist.controles.crewlist.ejecutar.ejecutarerror.title}`, {autoClose: true}); 
        }else{            
            // Firmar
            saveShowFirma(true);
        }

        saveEjecutando(false);
    }

    const prepareOptionsCboAgentes = () => {
        const newData = datosTablaAgentes.map((age) => {
            let aux = {value: age.idage, label: age.nom };
            return aux;
        })
        return newData;
    }

    const afterFirma = async () => {
        // Recargamos la tabla crewList
        await getDocsCrewList();
        selectAndGoDataTablesReg(formularioCabecera.id);
        await getLinesCrewList(formularioCabecera.id);
    }

    

    return(
        <div className="App wrapper">   
        {
            confPantalla !== undefined
            ?
                <Fragment>
                    <Alert fondo={true} />
                    <Menusup 
                        props = { props }
                    />
                    <Sidebar 
                        props = { props }
                    />
                </Fragment>
            :
            null
        } 
        <div className="content-wrapper">
            <div className="content-header">
            </div>
            <section className="content">                
                {
                    !tieneNivel 
                    ?
                        <PermissionRefused
                            traduction={confPantalla.config.trad.components.permissionrefused} 
                        />
                    :
                    <Fragment>
                    {
                        loadedConf === true && confPantalla !== undefined
                        ?
                        <div>                                                                
                            <Section titulo={ confPantalla.crewlist.section.titulo } icono={ confPantalla.crewlist.section.icono } col={ confPantalla.crewlist.section.col } idPrin={ formularioPrin.id } wiki={ confPantalla.crewlist.section.wiki } >
                                {
                                    loadingTablaDocsCrewList
                                    ?
                                        <div className="text-center">
                                            <Spinner animation="border" />
                                        </div>
                                    :
                                        null
                                }                            
                                <MiDataTableCrewList
                                    id={"docscrewlist"}
                                    columnas={confPantalla.crewlist.controles.crewlist.tabla.columns}
                                    data={datosTablaDocsCrewList}
                                    buttons={confPantalla.crewlist.controles.crewlist.tabla.buttons}
                                    ordering={confPantalla.crewlist.controles.crewlist.tabla.ordering}
                                    order={confPantalla.crewlist.controles.crewlist.tabla.order}
                                    searching={confPantalla.crewlist.controles.crewlist.tabla.searching}
                                    paging={confPantalla.crewlist.controles.crewlist.tabla.paging}
                                    select={confPantalla.crewlist.controles.crewlist.tabla.select}
                                    pagelength={confPantalla.crewlist.controles.crewlist.tabla.pagelength}                                   
                                    saveLoadingTable={ saveLoadingTablaDocsCrewList }
                                    loadingTable={loadingTablaDocsCrewList}
                                    columnactions={confPantalla.crewlist.controles.crewlist.tabla.columnactions.title}
                                    traduction={confPantalla.config.trad.components.datatable}
                                    functionAfterSelect={selectDocscrewlist}
                                    saveLoading={saveLoadingTablaDocsCrewList}
                                    confPantalla={confPantalla}
                                    editCabecera={editCabecera}
                                    clonarDoc={clonarDoc}
                                />
                                <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    <MiSelect  
                                        estadoForm=  { "" }                                     
                                        val=         { selectedAgente }   
                                        loading=     { loadReg ? true : false }
                                        id=          { confPantalla.crewlist.controles.agentes.id }    
                                        name=        { confPantalla.crewlist.controles.agentes.name }   
                                        label=       { confPantalla.crewlist.controles.agentes.label }    
                                        placeholder= { confPantalla.crewlist.controles.agentes.placeholder }
                                        col=         { confPantalla.crewlist.controles.agentes.col } 
                                        isClearable= { confPantalla.crewlist.controles.agentes.isclearable }
                                        isSearchable={ confPantalla.crewlist.controles.agentes.issearchable }
                                        dataType=    { confPantalla.crewlist.controles.agentes.datatype }
                                        data=        { confPantalla.crewlist.controles.agentes.data }
                                        required=    { confPantalla.crewlist.controles.agentes.required } 
                                        disabled=    { docEjecutable ? confPantalla.crewlist.controles.agentes.disabled : "disabled" }                                    
                                        readOnly =   { docEjecutable ? confPantalla.crewlist.controles.agentes.readonly : "readonly" }
                                        disablethisid={confPantalla.crewlist.controles.agentes.disablethisid}
                                        formulario= { {id:forceReloadAgente} }   
                                        change= { handleChangeAgente }
                                        disableidsarray={disableAgentes}
                                    />
                                    <Button 
                                        clases={ "col-2 mt-3"}
                                        color={ "primary" }  
                                        type="button" 
                                        icono={ "" }   
                                        id="btnaddagente"     
                                        texto={ confPantalla.crewlist.controles.btnaddagente.title }   
                                        accion={ addAgente } 
                                        disabled={ !selectedAgente? true: false }
                                    />
                                    <Button 
                                        clases={ "col-2 mt-3"}
                                        color={ "warning" }  
                                        type="button" 
                                        icono={ "" }   
                                        id="btnnewagente"     
                                        texto={ confPantalla.crewlist.controles.btnnewagente.title }   
                                        accion={ newAgente } 
                                        disabled={ false }
                                    />
                                    <Button 
                                        clases={ "col-2 mt-3"}
                                        color={ "success" }  
                                        type="button" 
                                        icono={ "" }   
                                        id="btnejecutar"     
                                        texto={ confPantalla.crewlist.controles.btnejecutar.title }   
                                        accion={ ejecutarBtn } 
                                        disabled={ !selectedDocCrewList || ejecutando || !docEjecutable ? true : false }
                                    />
                                </div>
                                {
                                    loadingTablaDocsCrewList || loadingTablaAgentes || ejecutando
                                    ?
                                        <div className="text-center">
                                            <Spinner animation="border" />
                                        </div>
                                    :
                                        null
                                }
                                {
                                    loadingTablaDocsCrewList || loadingTablaAgentes
                                    ?   null
                                    :
                                    <div className="mt-3 text-center">
                                        <div 
                                            className={!selectedDocCrewList  ? 'container-fluid containerSelectCrear mb-2' : 'container-fluid containerSelectEditar mb-2' }
                                            style={{padding: "10px 10px 10px 10px"}}
                                        >
                                                {
                                                    !selectedDocCrewList
                                                    ?
                                                        <h4 className='pl-3'>
                                                            {confPantalla.crewlist.controles.modo.crear.title}
                                                        </h4>
                                                    :
                                                        <h4 className='pl-3'>
                                                            {/* {confPantalla.crewlist.controles.modo.editar.title} */}
                                                            <span dangerouslySetInnerHTML={stringToHtml(titleEditar)} />
                                                        </h4>                                                                    
                                                }
                                        </div>

                                    </div>
                                }
                                <MiDataTableAgentes
                                    id={"docstablaagentes"}
                                    columnas={confPantalla.crewlist.controles.tabagentes.tabla.columns}
                                    data={datosTablaAgentes}
                                    buttons={confPantalla.crewlist.controles.tabagentes.tabla.buttons}
                                    ordering={confPantalla.crewlist.controles.tabagentes.tabla.ordering}
                                    order={confPantalla.crewlist.controles.tabagentes.tabla.order}
                                    searching={confPantalla.crewlist.controles.tabagentes.tabla.searching}
                                    paging={confPantalla.crewlist.controles.tabagentes.tabla.paging}
                                    select={confPantalla.crewlist.controles.tabagentes.tabla.select}
                                    pagelength={confPantalla.crewlist.controles.tabagentes.tabla.pagelength}                                   
                                    saveLoadingTable={ saveLoadingTablaAgentes }
                                    loadingTable={loadingTablaAgentes}
                                    columnactions={confPantalla.crewlist.controles.tabagentes.tabla.columnactions.title}
                                    traduction={confPantalla.config.trad.components.datatable}
                                    editAgente={editAgente}                                    
                                    saveLoading={saveLoadingTablaDocsCrewList}
                                    confPantalla={confPantalla}
                                    getLinesCrewList={getLinesCrewList}
                                />                               
                            </Section>
                        </div>
                        :
                        <LoadingPage />
                    }                    
                    </Fragment>                                                                                 
                }
                {
                    showFirma 
                    ?
                        <Firma
                            cabecera={formularioCabecera}
                            lineasFirmas={datosTablaAgentes}
                            controles={ confPantalla.crewlist.controles }
                            show={showFirma}
                            saveShow={saveShowFirma}
                            traduction={confPantalla.config.trad.components.firma}
                            functionAfter={afterFirma}
                            crewList={true}
                            numFirmasProp={1}
                            rangoAgentesProp={1}
                            dataAgentes={prepareOptionsCboAgentes()}
                        />
                    :
                        null 
                }
                {
                    showModalNewCrewList
                    ?
                        <Modal
                            show={showModalNewCrewList}
                            onHide={ () => saveShowModalNewCrewList(false)}                            
                            aria-labelledby="example-custom-modal-styling-title"
                            centered={true}                    
                            dialogClassName="modal-90w"                                             
                        > 
                            <Modal.Header closeButton>
                                {       
                                    accionClonar == true
                                    ?
                                        <h4>{confPantalla.crewlist.controles.modaladdcrewlist.headerclonar.title}</h4>
                                    :
                                    formularioCabecera.id == "" && accionClonar == false
                                    ?
                                        <h4>{confPantalla.crewlist.controles.modaladdcrewlist.headernuevo.title}</h4>
                                    :
                                        <h4>{confPantalla.crewlist.controles.modaladdcrewlist.headereditar.title}</h4>
                                }                                
                            </Modal.Header>                         
                            <Modal.Body>
                                <FormRow>
                                    <MiSelect  
                                        estadoForm=  { "" }                                    
                                        val=         { formularioCabecera.idcentro }   
                                        loading=     { loadReg ? true : false }
                                        id=          { confPantalla.crewlist.controles.modaladdcrewlist.idcentro.id }    
                                        name=        { confPantalla.crewlist.controles.modaladdcrewlist.idcentro.name }   
                                        label=       { confPantalla.crewlist.controles.modaladdcrewlist.idcentro.label }    
                                        placeholder= { confPantalla.crewlist.controles.modaladdcrewlist.idcentro.placeholder }
                                        col=         { confPantalla.crewlist.controles.modaladdcrewlist.idcentro.col } 
                                        isClearable= { confPantalla.crewlist.controles.modaladdcrewlist.idcentro.isclearable }
                                        isSearchable={ confPantalla.crewlist.controles.modaladdcrewlist.idcentro.issearchable }
                                        dataType=    { confPantalla.crewlist.controles.modaladdcrewlist.idcentro.datatype }
                                        data=        { confPantalla.crewlist.controles.modaladdcrewlist.idcentro.data }
                                        required=    { confPantalla.crewlist.controles.modaladdcrewlist.idcentro.required } 
                                        disabled=    { confPantalla.crewlist.controles.modaladdcrewlist.idcentro.disabled }                                    
                                        readOnly =   { confPantalla.crewlist.controles.modaladdcrewlist.idcentro.readonly }
                                        disablethisid={false}
                                        formulario= { {id:0} }   
                                        change= { handleSelectCabecera }
                                    />
                                    <MiSelect  
                                        estadoForm=  { "" }                                    
                                        val=         { formularioCabecera.tipo }   
                                        loading=     { loadReg ? true : false }
                                        id=          { confPantalla.crewlist.controles.modaladdcrewlist.tipo.id }    
                                        name=        { confPantalla.crewlist.controles.modaladdcrewlist.tipo.name }   
                                        label=       { confPantalla.crewlist.controles.modaladdcrewlist.tipo.label }    
                                        placeholder= { confPantalla.crewlist.controles.modaladdcrewlist.tipo.placeholder }
                                        col=         { confPantalla.crewlist.controles.modaladdcrewlist.tipo.col } 
                                        isClearable= { confPantalla.crewlist.controles.modaladdcrewlist.tipo.isclearable }
                                        isSearchable={ confPantalla.crewlist.controles.modaladdcrewlist.tipo.issearchable }
                                        dataType=    { confPantalla.crewlist.controles.modaladdcrewlist.tipo.datatype }
                                        data=        { confPantalla.crewlist.controles.modaladdcrewlist.tipo.data }
                                        required=    { confPantalla.crewlist.controles.modaladdcrewlist.tipo.required } 
                                        disabled=    { confPantalla.crewlist.controles.modaladdcrewlist.tipo.disabled }                                    
                                        readOnly =   { confPantalla.crewlist.controles.modaladdcrewlist.tipo.readonly }
                                        disablethisid={false}
                                        formulario= { {id:0} }   
                                        change= { handleSelectCabecera }
                                    />
                                    <FormInput                                                        
                                        estadoForm= { "" }
                                        value=      { formularioCabecera.numeroviaje }
                                        loading=    { loadReg ? true : false }
                                        id=         { confPantalla.crewlist.controles.modaladdcrewlist.numeroviaje.id }   
                                        type=       { confPantalla.crewlist.controles.modaladdcrewlist.numeroviaje.type }  
                                        name=       { confPantalla.crewlist.controles.modaladdcrewlist.numeroviaje.name }   
                                        label=      { confPantalla.crewlist.controles.modaladdcrewlist.numeroviaje.label }                                                       
                                        placeholder={ confPantalla.crewlist.controles.modaladdcrewlist.numeroviaje.placeholder }    
                                        col=        { confPantalla.crewlist.controles.modaladdcrewlist.numeroviaje.col } 
                                        required=   { confPantalla.crewlist.controles.modaladdcrewlist.numeroviaje.required }
                                        readonly=   { confPantalla.crewlist.controles.modaladdcrewlist.numeroviaje.readonly }                                                
                                        disabled=   { confPantalla.crewlist.controles.modaladdcrewlist.numeroviaje.disabled }
                                        change=     { handleInputCabecera }
                                        onBlur=     { null } 
                                    />
                                </FormRow>
                                <FormRow>
                                    <FormInput                                                        
                                        estadoForm= { "" }
                                        value=      { formularioCabecera.puertosalida}
                                        loading=    { loadReg ? true : false }
                                        id=         { confPantalla.crewlist.controles.modaladdcrewlist.puertosalida.id }   
                                        type=       { confPantalla.crewlist.controles.modaladdcrewlist.puertosalida.type }  
                                        name=       { confPantalla.crewlist.controles.modaladdcrewlist.puertosalida.name }   
                                        label=      { confPantalla.crewlist.controles.modaladdcrewlist.puertosalida.label }                                                       
                                        placeholder={ confPantalla.crewlist.controles.modaladdcrewlist.puertosalida.placeholder }    
                                        col=        { confPantalla.crewlist.controles.modaladdcrewlist.puertosalida.col } 
                                        required=   { confPantalla.crewlist.controles.modaladdcrewlist.puertosalida.required }
                                        readonly=   { confPantalla.crewlist.controles.modaladdcrewlist.puertosalida.readonly }                                                
                                        disabled=   { confPantalla.crewlist.controles.modaladdcrewlist.puertosalida.disabled }
                                        change=     { handleInputCabecera }
                                        onBlur=     { null } 
                                    />                                    
                                    <FormInput                                                        
                                        estadoForm= { "" }
                                        value=      { formularioCabecera.fechasalida}
                                        loading=    { loadReg ? true : false }
                                        id=         { confPantalla.crewlist.controles.modaladdcrewlist.fechasalida.id }   
                                        type=       { confPantalla.crewlist.controles.modaladdcrewlist.fechasalida.type }  
                                        name=       { confPantalla.crewlist.controles.modaladdcrewlist.fechasalida.name }   
                                        label=      { confPantalla.crewlist.controles.modaladdcrewlist.fechasalida.label }                                                       
                                        placeholder={ confPantalla.crewlist.controles.modaladdcrewlist.fechasalida.placeholder }    
                                        col=        { confPantalla.crewlist.controles.modaladdcrewlist.fechasalida.col } 
                                        required=   { confPantalla.crewlist.controles.modaladdcrewlist.fechasalida.required }
                                        readonly=   { confPantalla.crewlist.controles.modaladdcrewlist.fechasalida.readonly }                                                
                                        disabled=   { confPantalla.crewlist.controles.modaladdcrewlist.fechasalida.disabled }
                                        change=     { handleInputCabecera }
                                        onBlur=     { null } 
                                    />
                                    <FormInput                                                        
                                        estadoForm= { "" }
                                        value=      { formularioCabecera.ultimaescala}
                                        loading=    { loadReg ? true : false }
                                        id=         { confPantalla.crewlist.controles.modaladdcrewlist.ultimaescala.id }   
                                        type=       { confPantalla.crewlist.controles.modaladdcrewlist.ultimaescala.type }  
                                        name=       { confPantalla.crewlist.controles.modaladdcrewlist.ultimaescala.name }   
                                        label=      { confPantalla.crewlist.controles.modaladdcrewlist.ultimaescala.label }                                                       
                                        placeholder={ confPantalla.crewlist.controles.modaladdcrewlist.ultimaescala.placeholder }    
                                        col=        { confPantalla.crewlist.controles.modaladdcrewlist.ultimaescala.col } 
                                        required=   { confPantalla.crewlist.controles.modaladdcrewlist.ultimaescala.required }
                                        readonly=   { confPantalla.crewlist.controles.modaladdcrewlist.ultimaescala.readonly }                                                
                                        disabled=   { confPantalla.crewlist.controles.modaladdcrewlist.ultimaescala.disabled }
                                        change=     { handleInputCabecera }
                                        onBlur=     { null } 
                                    />
                                </FormRow>
                                <p className="font-italic text-center">{ confPantalla.crewlist.controles.modaladdcrewlist.txtrequired.title }</p>
                                {
                                    documentoDuplicado
                                    ?
                                    <div className="alert alert-danger text-center" role="alert">
                                        {confPantalla.crewlist.controles.modaladdcrewlist.docduplicado.title} 
                                        <a href={"/documentospr/"+documentoDuplicado.id} className="ml-1 btn btn-primary">
                                            <i className='fal fa-eye'></i>
                                        </a>
                                    </div>
                                    :
                                        null
                                }
                                {
                                    loadingCreateCrewList
                                    ?
                                        <div className="text-center">
                                            <Spinner animation="border" />
                                        </div>
                                    :
                                        null
                                }
                            </Modal.Body>
                            <Modal.Footer>
                                <div className='text-right'>                                    
                                    <Button 
                                        clases={ "" }
                                        color={ "success" }  
                                        type="button" 
                                        icono={ "" }   
                                        id="btncreateCrewList"     
                                        texto={ 
                                            accionClonar
                                            ? confPantalla.crewlist.controles.modaladdcrewlist.btnclonar.title
                                            : confPantalla.crewlist.controles.modaladdcrewlist.btnaceptar.title
                                        }   
                                        accion={ 
                                            accionClonar
                                            ? () => clonarDocumento()
                                            : () => createCrewList() 
                                        } 
                                        disabled={ 
                                            formularioCabecera.tipo == "" || formularioCabecera.idcentro == "" || formularioCabecera.puertosalida == "" || formularioCabecera.ultimaescala == "" || formularioCabecera.fechasalida == "" || loadingCreateCrewList
                                            ? true
                                            : false    
                                        }
                                    />
                                    <Button 
                                        clases={ "" }
                                        color={ "danger" }  
                                        type="button" 
                                        icono={ "" }   
                                        id="btncerrarmodalcrewlist"     
                                        texto={ confPantalla.crewlist.controles.modaladdcrewlist.btncerrar.title }   
                                        accion={ () => saveShowModalNewCrewList(false) } 
                                        disabled={ loadingCreateCrewList ? true : false }
                                    />                                   
                                </div>
                            </Modal.Footer>                            
                        </Modal>
                    :
                        null
                }
                {
                    showModalNewAgente
                    ?
                        <Modal
                            show={showModalNewAgente}
                            onHide={ () => saveShowModalNewAgente(false)}                            
                            aria-labelledby="example-custom-modal-styling-title"
                            centered={true}                    
                            dialogClassName="modal-90w"                                             
                        > 
                            <Modal.Header closeButton>
                                {
                                    ediAgente.id == ""
                                    ?
                                        <h4>{confPantalla.crewlist.controles.modalnewagente.headernuevo.title}</h4>
                                    :
                                        <h4>{confPantalla.crewlist.controles.modalnewagente.headereditar.title}</h4>
                                }
                            </Modal.Header>                         
                            <Modal.Body>
                                <FormRow>
                                    <FormInput                                                        
                                        estadoForm= { "" }
                                        value=      { ediAgente.nom }
                                        loading=    { loadReg ? true : false }
                                        id=         { confPantalla.agentespr.padre.controles.nom.id }   
                                        type=       { confPantalla.agentespr.padre.controles.nom.type }  
                                        name=       { confPantalla.agentespr.padre.controles.nom.name }   
                                        label=      { confPantalla.agentespr.padre.controles.nom.label+"*" }                                                       
                                        placeholder={ confPantalla.agentespr.padre.controles.nom.placeholder }    
                                        col=        { confPantalla.agentespr.padre.controles.nom.col } 
                                        required=   { confPantalla.agentespr.padre.controles.nom.required }
                                        readonly=   { confPantalla.agentespr.padre.controles.nom.readonly }                                                
                                        disabled=   { confPantalla.agentespr.padre.controles.nom.disabled }
                                        change=     { e => handleInputAgente(e) }
                                        onBlur=     {null} 
                                    />
                                    <MiSelectMultiple  
                                        estadoForm=  { "" }
                                        val=         { ediAgente.rel_agentes_tip  }   
                                        loading=     { loadReg ? true : false }
                                        id=          { confPantalla.agentespr.padre.controles.rel_agentes_tip.id }    
                                        name=        { confPantalla.agentespr.padre.controles.rel_agentes_tip.name }   
                                        label=       { confPantalla.agentespr.padre.controles.rel_agentes_tip.label }    
                                        placeholder= { confPantalla.agentespr.padre.controles.rel_agentes_tip.placeholder }
                                        col=         { confPantalla.agentespr.padre.controles.rel_agentes_tip.col } 
                                        isClearable= { confPantalla.agentespr.padre.controles.rel_agentes_tip.isclearable }
                                        isSearchable={ confPantalla.agentespr.padre.controles.rel_agentes_tip.issearchable }
                                        dataType=    { confPantalla.agentespr.padre.controles.rel_agentes_tip.datatype }
                                        data=        { confPantalla.agentespr.padre.controles.rel_agentes_tip.data }
                                        required=    { confPantalla.agentespr.padre.controles.rel_agentes_tip.required } 
                                        disabled=  { confPantalla.agentespr.padre.controles.rel_agentes_tip.disabled }
                                        readOnly =   { confPantalla.agentespr.padre.controles.rel_agentes_tip.readonly }
                                        disablethisid={confPantalla.agentespr.padre.controles.rel_agentes_tip.disablethisid}
                                        formulario= { ediAgente }   
                                        change= { handleSelectMultipleAgente }
                                    />
                                    <MiSelect  
                                        estadoForm=  { "" }
                                        val=         { ediAgente.idpais  }   
                                        loading=     { loadReg ? true : false }
                                        id=          { confPantalla.agentespr.padre.controles.pais.id }    
                                        name=        { confPantalla.agentespr.padre.controles.pais.name }   
                                        label=       { confPantalla.agentespr.padre.controles.pais.label }    
                                        placeholder= { confPantalla.agentespr.padre.controles.pais.placeholder }
                                        col=         { confPantalla.agentespr.padre.controles.pais.col } 
                                        isClearable= { confPantalla.agentespr.padre.controles.pais.isclearable }
                                        isSearchable={ confPantalla.agentespr.padre.controles.pais.issearchable }
                                        dataType=    { confPantalla.agentespr.padre.controles.pais.datatype }
                                        data=        { confPantalla.agentespr.padre.controles.pais.data }
                                        required=    { confPantalla.agentespr.padre.controles.pais.required } 
                                        disabled=  { confPantalla.agentespr.padre.controles.pais.disabled }
                                        readOnly =   { confPantalla.agentespr.padre.controles.pais.readonly }
                                        disablethisid={confPantalla.agentespr.padre.controles.pais.disablethisid}
                                        formulario= { ediAgente }   
                                        change= { handleSelectAgente }
                                    />
                                </FormRow>
                                <FormRow>
                                    <FormInput                                                        
                                        estadoForm= { "" }
                                        value=      { ediAgente.fec1 }
                                        loading=    { loadReg ? true : false }
                                        id=         { confPantalla.agentespr.padre.controles.fec1.id }   
                                        type=       { confPantalla.agentespr.padre.controles.fec1.type }  
                                        name=       { confPantalla.agentespr.padre.controles.fec1.name }   
                                        label=      { confPantalla.agentespr.padre.controles.fec1.label }                                                       
                                        placeholder={ confPantalla.agentespr.padre.controles.fec1.placeholder }    
                                        col=        { confPantalla.agentespr.padre.controles.fec1.col } 
                                        required=   { confPantalla.agentespr.padre.controles.fec1.required }
                                        readonly=   { confPantalla.agentespr.padre.controles.fec1.readonly }                                                
                                        disabled=   { confPantalla.agentespr.padre.controles.fec1.disabled }
                                        change=     { e => handleInputAgente(e) }
                                        onBlur=     {null} 
                                    />
                                    <FormInput                                                        
                                        estadoForm= { "" }
                                        value=      { ediAgente.pro }
                                        loading=    { loadReg ? true : false }
                                        id=         { confPantalla.agentespr.padre.controles.pro.id }   
                                        type=       { confPantalla.agentespr.padre.controles.pro.type }  
                                        name=       { confPantalla.agentespr.padre.controles.pro.name }   
                                        label=      { confPantalla.agentespr.padre.controles.pro.label }                                                       
                                        placeholder={ confPantalla.agentespr.padre.controles.pro.placeholder }    
                                        col=        { confPantalla.agentespr.padre.controles.pro.col } 
                                        required=   { confPantalla.agentespr.padre.controles.pro.required }
                                        readonly=   { confPantalla.agentespr.padre.controles.pro.readonly }                                                
                                        disabled=   { confPantalla.agentespr.padre.controles.pro.disabled }
                                        change=     { e => handleInputAgente(e) }
                                        onBlur=     {null} 
                                    />                                  
                                    <MiSelect  
                                        estadoForm=  { confPantalla.agentespr.padre.estadoform }
                                        val=         { parseInt(ediAgente.pob)  }   
                                        loading=     { loadReg ? true : false }
                                        id=          { confPantalla.agentespr.padre.controles.pob.id }    
                                        name=        { confPantalla.agentespr.padre.controles.pob.name }   
                                        label=       { confPantalla.agentespr.padre.controles.pob.label }    
                                        placeholder= { confPantalla.agentespr.padre.controles.pob.placeholder }
                                        col=         { confPantalla.agentespr.padre.controles.pob.col } 
                                        isClearable= { confPantalla.agentespr.padre.controles.pob.isclearable }
                                        isSearchable={ confPantalla.agentespr.padre.controles.pob.issearchable }
                                        dataType=    { confPantalla.agentespr.padre.controles.pob.datatype }
                                        data=        { confPantalla.agentespr.padre.controles.pob.data }
                                        required=    { confPantalla.agentespr.padre.controles.pob.required } 
                                        disabled=  { confPantalla.agentespr.padre.controles.pob.disabled }
                                        readOnly =   { confPantalla.agentespr.padre.controles.pob.readonly }
                                        disablethisid={confPantalla.agentespr.padre.controles.pob.disablethisid}
                                        formulario= { ediAgente }   
                                        change= { handleSelectAgente }
                                    />
                                </FormRow>
                                <FormRow>                                    
                                    <MiSelect  
                                        estadoForm=  { confPantalla.agentespr.padre.estadoform }
                                        val=         { parseInt(ediAgente.txt2)  }   
                                        loading=     { loadReg ? true : false }
                                        id=          { confPantalla.agentespr.padre.controles.txt2.id }    
                                        name=        { confPantalla.agentespr.padre.controles.txt2.name }   
                                        label=       { confPantalla.agentespr.padre.controles.txt2.label }    
                                        placeholder= { confPantalla.agentespr.padre.controles.txt2.placeholder }
                                        col=         { confPantalla.agentespr.padre.controles.txt2.col } 
                                        isClearable= { confPantalla.agentespr.padre.controles.txt2.isclearable }
                                        isSearchable={ confPantalla.agentespr.padre.controles.txt2.issearchable }
                                        dataType=    { confPantalla.agentespr.padre.controles.txt2.datatype }
                                        data=        { confPantalla.agentespr.padre.controles.txt2.data }
                                        required=    { confPantalla.agentespr.padre.controles.txt2.required } 
                                        disabled=  { confPantalla.agentespr.padre.controles.txt2.disabled }
                                        readOnly =   { confPantalla.agentespr.padre.controles.txt2.readonly }
                                        disablethisid={confPantalla.agentespr.padre.controles.txt2.disablethisid}
                                        formulario= { formularioPrin }   
                                        change= { handleSelectAgente }
                                    />
                                    <FormInput                                                        
                                        estadoForm= { "" }
                                        value=      { ediAgente.web }
                                        loading=    { loadReg ? true : false }
                                        id=         { confPantalla.agentespr.padre.controles.web.id }   
                                        type=       { confPantalla.agentespr.padre.controles.web.type }  
                                        name=       { confPantalla.agentespr.padre.controles.web.name }   
                                        label=      { confPantalla.agentespr.padre.controles.web.label }                                                       
                                        placeholder={ confPantalla.agentespr.padre.controles.web.placeholder }    
                                        col=        { confPantalla.agentespr.padre.controles.web.col } 
                                        required=   { confPantalla.agentespr.padre.controles.web.required }
                                        readonly=   { confPantalla.agentespr.padre.controles.web.readonly }                                                
                                        disabled=   { confPantalla.agentespr.padre.controles.web.disabled }
                                        change=     { e => handleInputAgente(e) }
                                        onBlur=     {null} 
                                    />
                                    <FormInput                                                        
                                        estadoForm= { "" }
                                        value=      { ediAgente.txt1 }
                                        loading=    { loadReg ? true : false }
                                        id=         { confPantalla.agentespr.padre.controles.txt1.id }   
                                        type=       { confPantalla.agentespr.padre.controles.txt1.type }  
                                        name=       { confPantalla.agentespr.padre.controles.txt1.name }   
                                        label=      { confPantalla.agentespr.padre.controles.txt1.label }                                                       
                                        placeholder={ confPantalla.agentespr.padre.controles.txt1.placeholder }    
                                        col=        { confPantalla.agentespr.padre.controles.txt1.col } 
                                        required=   { confPantalla.agentespr.padre.controles.txt1.required }
                                        readonly=   { confPantalla.agentespr.padre.controles.txt1.readonly }                                                
                                        disabled=   { confPantalla.agentespr.padre.controles.txt1.disabled }
                                        change=     { e => handleInputAgente(e) }
                                        onBlur=     {null} 
                                    />
                                    <FormInput                                                        
                                        estadoForm= { "" }
                                        value=      { ediAgente.fec2 }
                                        loading=    { loadReg ? true : false }
                                        id=         { confPantalla.agentespr.padre.controles.fec2.id }   
                                        type=       { confPantalla.agentespr.padre.controles.fec2.type }  
                                        name=       { confPantalla.agentespr.padre.controles.fec2.name }   
                                        label=      { confPantalla.agentespr.padre.controles.fec2.label }                                                       
                                        placeholder={ confPantalla.agentespr.padre.controles.fec2.placeholder }    
                                        col=        { confPantalla.agentespr.padre.controles.fec2.col } 
                                        required=   { confPantalla.agentespr.padre.controles.fec2.required }
                                        readonly=   { confPantalla.agentespr.padre.controles.fec2.readonly }                                                
                                        disabled=   { confPantalla.agentespr.padre.controles.fec2.disabled }
                                        change=     { e => handleInputAgente(e) }
                                        onBlur=     {null} 
                                    />
                                </FormRow>
                                <p className="font-italic text-center">{ confPantalla.crewlist.controles.modalnewagente.txtrequired.title }</p>
                                {
                                    agenteDuplicado
                                    ?
                                    <div className="alert alert-danger text-center" role="alert">
                                        {confPantalla.crewlist.controles.modalnewagente.ageduplicado.title} 
                                        <a href={"/agentespr/"+agenteDuplicado.id} className="alert-link">
                                            {agenteDuplicado.nom}
                                        </a>
                                    </div>
                                    :
                                        null
                                }
                                {
                                    loadingSaveAgente
                                    ?
                                        <div className="text-center">
                                            <Spinner animation="border" />
                                        </div>
                                    :
                                        null
                                }
                            </Modal.Body>
                            <Modal.Footer>
                                <div className='text-right'>                                   
                                    <Button 
                                        clases={ "" }
                                        color={ "success" }  
                                        type="button" 
                                        icono={ "" }   
                                        id=""     
                                        texto={confPantalla.crewlist.controles.modalnewagente.btnguardar.title}   
                                        accion={ saveAgente } 
                                        disabled={ loadingSaveAgente || ediAgente.nom == "" ? true : false }
                                    />
                                    <Button 
                                        clases={ "" }
                                        color={ "danger" }  
                                        type="button" 
                                        icono={ "" }   
                                        id=""     
                                        texto={confPantalla.crewlist.controles.modalnewagente.btncerrar.title}   
                                        accion={ () => saveShowModalNewAgente(false) } 
                                        disabled={ loadingSaveAgente ? true : false }
                                    />                                    
                                </div>
                            </Modal.Footer>                            
                        </Modal>
                    :
                        null
                }   
            </section>                                    
        </div>
        <Footer />        
        </div>

    );

}
export default CrewList